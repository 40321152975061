import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

// utils
import { pushEventToDataLayer } from '../utils/dataLayer'
import { DATA_LAYER_EVENTS, DEFAULT_SCREEN_NAME, GA_SCREEN_CLASS, GA_SCREEN_NAME } from '../utils/dataLayerEnums'

// redux
import { setGaScreenName } from '../reducers/googleAnalytics/googleAnalyticsActions'

/**
 * push screen_view event to dataLayer on page (component) load
 * push screen_duration event to dataLayer on page (component) leave (or when user closes the tab / browser)
 */
const usePushScreenInfoToDataLayer = <OtherDataType extends Record<string, any> = {}>(
	screenName: GA_SCREEN_NAME,
	screenClass: GA_SCREEN_CLASS,
	otherData?: OtherDataType,
	enableDataLayerPush: boolean = true
) => {
	const dispatch = useDispatch()
	const [wasPushed, setWasPushed] = useState(false)

	useEffect(() => {
		// NOTE: enableDataLayerPush is optional, if not provided, it will default to true
		// OtherData can be asynchronous, so we need to check if exists
		// If enableDataLayerPush is not provided (undefined) it will default to true

		if (wasPushed || !enableDataLayerPush) {
			return
		}

		let event = {
			event: DATA_LAYER_EVENTS.SCREEN_VIEW,
			screen_name: screenName,
			screen_class: screenClass
		}

		if (otherData) {
			event = { ...event, ...otherData }
		}

		pushEventToDataLayer(event)
		setWasPushed(true)
	}, [screenName, screenClass, otherData, enableDataLayerPush, wasPushed])

	useEffect(() => {
		dispatch(setGaScreenName(screenName))

		if (wasPushed) {
			const startTime = dayjs()

			const onPageLeave = (start: dayjs.Dayjs) => {
				pushEventToDataLayer({ event: DATA_LAYER_EVENTS.SCREEN_DURATION, screen_name: screenName, screen_time: dayjs().diff(start, 'ms') })
				// reset to default screen name in case the new page won't update redux with gaScreenName
				dispatch(setGaScreenName(DEFAULT_SCREEN_NAME))
			}

			// push to data layer on page refresh or tab close
			const onBeforeUnload = () => onPageLeave(startTime)

			window.addEventListener('beforeunload', onBeforeUnload)

			return () => {
				window.removeEventListener('beforeunload', onBeforeUnload)
				// push to data layer on component unmount
				onPageLeave(startTime)
			}
		}

		return () => {}
	}, [screenName, wasPushed, dispatch])

	return null
}

export default usePushScreenInfoToDataLayer
