import React, { ComponentProps, FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFormInitialValues, initialize, submit } from 'redux-form'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// components
import ConfirmModal, { IConfirmModal } from '../../atoms/ConfirmModal'
import ConfirmBulkForm from './ConfirmBulkForm'
import CancelReservationConfirmForm from './CancelReservationConfirmForm'

// types
import {
	IBulkConfirmForm,
	ConfirmModalData,
	IAuthUserPayload,
	ICalendarReservationForm,
	ICancelReservationConfirmForm,
	ConfirmModalReservationData,
	ConfirmModalEventData,
	ConfirmModalDeleteEventData,
	ConfirmModalUpdateReservationData
} from '../../types/interfaces'
import { RootState } from '../../reducers'

// utils
import { CALENDAR_DISABLED_NOTIFICATION_TYPE, CALENDAR_EVENT_TYPE, CONFIRM_BULK, CONFIRM_MODAL_DATA_TYPE, FORM, REQUEST_TYPE, RESERVATION_STATE, STRINGS } from '../../utils/enums'
import { getDateTime, getConfirmModalText } from '../../utils/helper'
import { DATA_LAYER_EVENTS, GA_FORM_NAME } from '../../utils/dataLayerEnums'

// assets
import CloseIcon from '../../assets/icons/close-icon-modal.svg?react'

// hooks
import { CancelReservationFormChanges, getGaCancelReservationFormChanges, pushEventToDataLayer } from '../../utils/dataLayer'

type Props = {
	data: ConfirmModalData
	loadingData?: boolean
	queryEventId?: string | null
	clearConfirmModal: () => void
	salonID?: string
	googleAnalyticsData?: {
		onConfirmPendingReservationAfterNow?: () => void
		cancelConfirmModalScreenInfo?: NonNullable<ComponentProps<typeof CancelReservationConfirmForm>>['cancelConfirmModalScreenInfo']
	}
}

interface IConfirmModalState extends IConfirmModal {
	content: React.ReactNode
	reservationState?: RESERVATION_STATE
}

const INIT_CONFIRM_MODAL_VALUES: IConfirmModalState = {
	open: false,
	onOk: undefined,
	onCancel: undefined,
	content: null,
	title: undefined,
	reservationState: undefined
}

const isOnlyReservationNoteChanged = (initialValues?: Partial<ICalendarReservationForm>, values?: Partial<ICalendarReservationForm>) => {
	const areValuesWithoutNoteTheSame =
		initialValues?.customer?.key === values?.customer?.key &&
		initialValues?.service?.value === values?.service?.value &&
		initialValues?.date === values?.date &&
		initialValues?.timeFrom === values?.timeFrom &&
		initialValues?.employee?.key === values?.employee?.key

	const isNoteChanged = !!(!(!initialValues?.note && !values?.note) || (initialValues?.note && values?.note && initialValues.note !== values.note))

	return areValuesWithoutNoteTheSame && isNoteChanged
}

const isAuthUserAssignedEmployee = (salonID?: string, authUser?: IAuthUserPayload['data'], values?: ICalendarReservationForm) => {
	const authUserEmployeeID = authUser?.salons.find((salon) => salon.id === salonID)?.employeeID
	return authUserEmployeeID === values?.employee?.key
}

const CalendarConfirmModal: FC<Props> = (props) => {
	const { loadingData, queryEventId, data, clearConfirmModal, salonID, googleAnalyticsData } = props
	const { onConfirmPendingReservationAfterNow } = googleAnalyticsData || {}

	const [confirmModal, setConfirmModal] = useState<IConfirmModalState>(INIT_CONFIRM_MODAL_VALUES)
	const disabledNotifications = useSelector((state: RootState) => state.selectedSalon.selectedSalon.data?.settings?.disabledNotifications)

	const initialReservationFormValues = useSelector(getFormInitialValues(FORM.CALENDAR_RESERVATION_FORM)) as Partial<ICalendarReservationForm> | undefined

	const authUser = useSelector((state: RootState) => state.user.authUser)

	const [t] = useTranslation()

	const dispatch = useDispatch()

	const handleSubmitReservationWrapper = ({ values }: ConfirmModalReservationData['data'], handleSubmit: ConfirmModalReservationData['handleSubmit']) => {
		// wrapper ktory rozhoduje, ci je potrebne potvrdit event alebo rovno submit-nut
		// NOTE: ak je eventID z values tak sa funkcia vola z drag and drop / resize ak ide z query tak je otvoreny detail cez URL / kliknutim na bunku
		const eventId = (values?.updateFromCalendar ? values?.eventId : queryEventId) || undefined
		const onlyReservationNoteChanged = isOnlyReservationNoteChanged(initialReservationFormValues, values)

		if (eventId) {
			const newReservationDateTimeTo = getDateTime(values.date, values.timeTo)
			const isPendingReservationAfterNow = values.reservationData?.state === RESERVATION_STATE.PENDING && dayjs(newReservationDateTimeTo).isAfter(dayjs())

			setConfirmModal({
				open: true,
				title: STRINGS(t).edit(t('loc:rezerváciu')),
				onOk: () => {
					if (isPendingReservationAfterNow && onConfirmPendingReservationAfterNow) {
						onConfirmPendingReservationAfterNow()
					}
					handleSubmit(values, eventId)
				},
				onCancel: () => {
					if (values.revertEvent) {
						values.revertEvent()
					}
					clearConfirmModal()
				},
				content: getConfirmModalText(
					isPendingReservationAfterNow ? t('loc:Chcete pokračovať? Úpravou nepotvrdenej rezervácie dôjde k jej potvrdeniu.') : t('loc:Naozaj chcete upraviť rezerváciu?'),
					[CALENDAR_DISABLED_NOTIFICATION_TYPE.RESERVATION_CHANGED_CUSTOMER, CALENDAR_DISABLED_NOTIFICATION_TYPE.RESERVATION_CHANGED_EMPLOYEE],
					disabledNotifications,
					onlyReservationNoteChanged,
					onlyReservationNoteChanged && isAuthUserAssignedEmployee(salonID, authUser.data, values)
				)
			})
		} else {
			handleSubmit(values, eventId)
		}
	}

	const handleSubmitEventWrapper = ({ values }: ConfirmModalEventData['data'], handleSubmit: ConfirmModalEventData['handleSubmit']) => {
		// wrapper ktory rozhoduje, ci je potrebne potvrdit event alebo rovno submitnut
		// NOTE: ak je eventID z values tak sa funkcia vola z drag and drop / resize ak ide z query tak je otvoreny detail cez URL / kliknutim na bunku
		const eventId = (values?.updateFromCalendar ? values?.eventId : queryEventId) || undefined

		if (values.calendarBulkEventID) {
			dispatch(initialize(FORM.CONFIRM_BULK_FORM, { actionType: CONFIRM_BULK.BULK }))

			const handleSubmitConfirmBulkForm = (bulkFormValues: IBulkConfirmForm) => {
				handleSubmit(values, eventId, bulkFormValues.actionType === CONFIRM_BULK.BULK ? values.calendarBulkEventID : undefined, values?.updateFromCalendar)
			}

			setConfirmModal({
				open: true,
				title: STRINGS(t).edit(t('loc:udalosť')),
				onOk: () => dispatch(submit(FORM.CONFIRM_BULK_FORM)),
				onCancel: () => {
					if (values.revertEvent) {
						values.revertEvent()
					}
					clearConfirmModal()
				},
				content: <ConfirmBulkForm requestType={REQUEST_TYPE.PATCH} onSubmit={handleSubmitConfirmBulkForm} />
			})
		} else {
			handleSubmit(values, eventId, undefined, values?.updateFromCalendar)
		}
	}

	const handleDeleteEventWrapper = (
		{ eventId, calendarBulkEventID, eventType }: ConfirmModalDeleteEventData['data'],
		handleSubmit: ConfirmModalDeleteEventData['handleSubmit']
	) => {
		// wrapper ktory rozhoduje, ci je potrebne potvrdit event alebo rovno submitnut
		// v tomto pripade zatial vyskoci vzdy konfirmacny modal
		let modalProps: IConfirmModalState = {} as IConfirmModalState

		if (calendarBulkEventID) {
			dispatch(initialize(FORM.CONFIRM_BULK_FORM, { actionType: CONFIRM_BULK.BULK }))

			const handleSubmitConfirmBulkForm = (bulkFormValues: IBulkConfirmForm) => {
				handleSubmit(eventId, bulkFormValues.actionType === CONFIRM_BULK.BULK ? calendarBulkEventID : undefined)
			}

			modalProps = {
				...modalProps,
				content: <ConfirmBulkForm requestType={REQUEST_TYPE.DELETE} eventType={eventType} onSubmit={handleSubmitConfirmBulkForm} />,
				onOk: () => dispatch(submit(FORM.CONFIRM_BULK_FORM))
			}
		} else {
			const deleteMessage = STRINGS(t).areYouSureDelete(t('loc:udalosť'))
			modalProps = {
				...modalProps,
				content:
					eventType === CALENDAR_EVENT_TYPE.RESERVATION
						? getConfirmModalText(
								deleteMessage,
								[CALENDAR_DISABLED_NOTIFICATION_TYPE.RESERVATION_CANCELLED_CUSTOMER, CALENDAR_DISABLED_NOTIFICATION_TYPE.RESERVATION_CANCELLED_EMPLOYEE],
								disabledNotifications
							)
						: deleteMessage,
				onOk: () => handleSubmit(eventId, calendarBulkEventID)
			}
		}

		setConfirmModal({
			open: true,
			title: STRINGS(t).delete(t('loc:udalosť')),
			onCancel: () => clearConfirmModal(),
			...modalProps
		})
	}
	const handleUpdateReservationStateWrapper = (updateStateData: ConfirmModalUpdateReservationData['data'], handleSubmit: ConfirmModalUpdateReservationData['handleSubmit']) => {
		// wrapper ktory rozhoduje, ci je potrebne potvrdit event alebo rovno submit-nut
		if (
			updateStateData.state === RESERVATION_STATE.DECLINED ||
			updateStateData.state === RESERVATION_STATE.NOT_REALIZED ||
			updateStateData.state === RESERVATION_STATE.CANCEL_BY_SALON
		) {
			let modalProps: IConfirmModalState = {} as IConfirmModalState

			switch (updateStateData.state) {
				case RESERVATION_STATE.DECLINED:
					modalProps = {
						...modalProps,
						onOk: () => handleSubmit(updateStateData),
						onCancel: () => clearConfirmModal(),
						title: STRINGS(t).decline(t('loc:rezerváciu')),
						content: getConfirmModalText(
							t('loc:Naozaj chcete zamietnuť rezerváciu?'),
							[CALENDAR_DISABLED_NOTIFICATION_TYPE.RESERVATION_REJECTED_CUSTOMER, CALENDAR_DISABLED_NOTIFICATION_TYPE.RESERVATION_REJECTED_EMPLOYEE],
							disabledNotifications
						)
					}
					break
				case RESERVATION_STATE.CANCEL_BY_SALON:
					{
						const handleCancelReservationConfirmForm = (values: ICancelReservationConfirmForm) => {
							pushEventToDataLayer<CancelReservationFormChanges>({
								event: DATA_LAYER_EVENTS.CLICK_BUTTON,
								button_name: 'finish_cancel_reservation',
								form_name: GA_FORM_NAME.CANCEL_RESERVATION_FORM,
								...getGaCancelReservationFormChanges(values)
							})

							handleSubmit({
								...updateStateData,
								quickFeedbackAnswers: values.quickFeedbackAnswers,
								text: values?.text
							})
						}
						modalProps = {
							...modalProps,
							reservationState: RESERVATION_STATE.CANCEL_BY_SALON,
							maskClosable: false,
							okText: STRINGS(t).cancel(t('loc:rezerváciu')),
							cancelText: t('loc:Ísť späť'),
							title: STRINGS(t).cancel(t('loc:rezerváciu')),
							onOk: () => {
								dispatch(submit(FORM.CANCEL_RESERVATION_CONFIRM))
							},
							onCancel: () => {
								pushEventToDataLayer({
									event: DATA_LAYER_EVENTS.CLICK_BUTTON,
									button_name: 'stop_cancel_reservation',
									form_name: GA_FORM_NAME.CANCEL_RESERVATION_FORM
								})
								clearConfirmModal()
							},
							content: (
								<CancelReservationConfirmForm
									open={confirmModal.open}
									onSubmit={handleCancelReservationConfirmForm}
									cancelConfirmModalScreenInfo={googleAnalyticsData?.cancelConfirmModalScreenInfo}
								/>
							)
						}
					}
					break
				case RESERVATION_STATE.NOT_REALIZED:
					modalProps = {
						...modalProps,
						onOk: () => handleSubmit(updateStateData),
						onCancel: () => clearConfirmModal(),
						title: t('loc:Nezrealizovaná'),
						content: t('loc:Naozaj chcete označiť rezerváciu za nezrealizovanú?')
					}
					break
				default:
					break
			}

			setConfirmModal({
				open: true,
				okText: t('loc:Áno'),
				cancelText: t('loc:Nie'),
				...modalProps
			})
		} else {
			handleSubmit(updateStateData)
		}
	}

	useEffect(() => {
		switch (data?.key) {
			case CONFIRM_MODAL_DATA_TYPE.RESERVATION:
				handleSubmitReservationWrapper(data.data, data.handleSubmit)
				break
			case CONFIRM_MODAL_DATA_TYPE.EVENT:
				handleSubmitEventWrapper(data.data, data.handleSubmit)
				break
			case CONFIRM_MODAL_DATA_TYPE.DELETE_EVENT: {
				handleDeleteEventWrapper(data.data, data.handleSubmit)
				break
			}
			case CONFIRM_MODAL_DATA_TYPE.UPDATE_RESERVATION_STATE: {
				handleUpdateReservationStateWrapper(data.data, data.handleSubmit)
				break
			}
			default:
				setConfirmModal(INIT_CONFIRM_MODAL_VALUES)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	return (
		<ConfirmModal loading={loadingData} disabled={loadingData} closeIcon={<CloseIcon />} destroyOnClose zIndex={2000} {...confirmModal}>
			{confirmModal.content}
		</ConfirmModal>
	)
}

export default CalendarConfirmModal
