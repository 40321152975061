import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row, Spin, Tooltip } from 'antd'
import { TableProps, ColumnsType } from 'antd/lib/table'
import { initialize } from 'redux-form'

// components
import { Link } from 'react-router-dom'
import CustomTable from '../../components/CustomTable'
import SalonsToCheckFilter from './components/filters/SalonsToCheckFilter'

// utils
import { FORM, ROW_GUTTER_X_DEFAULT, SALONS_ORDER, SALONS_TAB_KEYS } from '../../utils/enums'
import { getLinkWithEncodedBackUrl, normalizeDirectionKeys, setOrder } from '../../utils/helper'
import { SalonsPageCommonProps, getSalonsColumns } from './components/salonUtils'
import { LocalizedDateTime } from '../../utils/intl'

// reducers
import { getSalonsToCheck } from '../../reducers/salons/salonsActions'
import { RootState } from '../../reducers'
import { getCategories } from '../../reducers/categories/categoriesActions'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// schema
import { salonsToCheckPageURLQueryParamsSchema } from '../../schemas/queryParams'

// types
import { GetUrls, ISalonsToCheckFilter, RequestResponse } from '../../types/interfaces'

type Props = SalonsPageCommonProps & {}

type TableDataItem = RequestResponse<GetUrls['/api/b2b/admin/salons/to-check']>['salons'][0]

const SalonsToCheckPage: React.FC<Props> = (props) => {
	const { selectedCountry, dispatch, t, navigate } = props

	const salons = useSelector((state: RootState) => state.salons.salonsToCheck)

	useEffect(() => {
		dispatch(getCategories())
	}, [dispatch])

	const [query, setQuery] = useQueryParams(salonsToCheckPageURLQueryParamsSchema, {
		page: 1,
		order: SALONS_ORDER.CREATED_AT_DESC
	})

	const countryCode = selectedCountry || query.countryCode

	const salonsColumns = useMemo(() => getSalonsColumns(query.order), [query.order])

	useEffect(() => {
		dispatch(
			initialize(FORM.SALONS_TO_CHECK_FILTER, {
				search: query.search,
				statuses_all: query.statuses_all,
				statuses_published: query.statuses_published,
				statuses_changes: query.statuses_changes,
				createType: query.createType,
				assignedUserID: query.assignedUserID,
				countryCode
			})
		)
		dispatch(
			getSalonsToCheck({
				page: query.page,
				limit: query.limit,
				search: query.search,
				statuses_all: query.statuses_all,
				statuses_published: query.statuses_published,
				statuses_changes: query.statuses_changes,
				createType: query.createType,
				assignedUserID: query.assignedUserID,
				order: query.order,
				countryCode
			})
		)
	}, [
		dispatch,
		query.page,
		query.limit,
		query.search,
		query.statuses_all,
		query.statuses_changes,
		query.statuses_published,
		query.createType,
		query.assignedUserID,
		query.order,
		countryCode
	])

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (_pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}` as SALONS_ORDER
			const newQuery = {
				...query,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page
		}
		setQuery(newQuery)
	}

	const handleSubmitFilter = (values: ISalonsToCheckFilter) => {
		const newQuery = {
			...query,
			...values,
			page: 1
		}
		setQuery(newQuery)
	}

	const columns: ColumnsType<any> = [
		salonsColumns.id({ width: '8%' }),
		salonsColumns.name({ width: '15%' }),
		salonsColumns.address({ width: '15%' }),
		salonsColumns.isPublished({ width: '8%' }),
		salonsColumns.changes({ width: '10%' }),
		salonsColumns.createType({ width: '10%' }),
		salonsColumns.premiumSourceUserType({ width: '6%' }),
		salonsColumns.assignedUser({ width: '10%' }),
		salonsColumns.lastUpdatedAt({ width: '10%', sorter: true, sortDirections: ['ascend', 'descend', 'ascend'], sortOrder: setOrder(query.order, 'lastUpdatedAt') }),
		salonsColumns.createdAt({ width: '10%' }),
		{
			title: t('loc:Skontroloval'),
			dataIndex: 'checker',
			key: 'checker',
			width: '8%',
			render: (value: TableDataItem['checker']) => {
				if (value) {
					let name
					if (value.name) {
						name = <div className={'truncate'}>{value.name}</div>
					} else {
						const firstThree = value.id.substring(0, 3)
						const lastThree = value.id.substring(value.id.length - 3)

						name = <Tooltip title={value.id}>{`${firstThree}...${lastThree}`}</Tooltip>
					}

					return (
						<Link to={`${t('paths:users')}/${value.id}`} onClick={(e) => e.stopPropagation()} className={'text-notino-pink hover:text-black'}>
							{name}
						</Link>
					)
				}

				return '-'
			}
		},
		{
			title: t('loc:Dátum poslednej kontroly'),
			dataIndex: 'checkedAt',
			key: 'checkedAt',
			width: '8%',
			render: (value: TableDataItem['checkedAt']) => <LocalizedDateTime date={value} fallback={'-'} ellipsis />
		}
	]

	return (
		<Row gutter={ROW_GUTTER_X_DEFAULT}>
			<Col span={24}>
				<Spin spinning={salons?.isLoading}>
					<div className='content-body'>
						<SalonsToCheckFilter onSubmit={handleSubmitFilter} query={{ ...query, countryCode, salonState: SALONS_TAB_KEYS.TO_CHECK }} />
						<CustomTable
							className='table-fixed'
							onChange={onChangeTable}
							columns={columns || []}
							dataSource={salons?.data?.salons}
							scroll={{ x: 1500 }}
							rowKey='id'
							rowClassName={'clickable-row'}
							twoToneRows
							useCustomPagination
							pagination={{
								pageSize: salons?.data?.pagination?.limit,
								total: salons?.data?.pagination?.totalCount,
								current: salons?.data?.pagination?.page,
								onChange: onChangePagination,
								disabled: salons?.isLoading
							}}
							onRow={(record) => ({
								onClick: () => {
									navigate(getLinkWithEncodedBackUrl(t('paths:salons/{{salonID}}', { salonID: record.id })))
								}
							})}
						/>
					</div>
				</Spin>
			</Col>
		</Row>
	)
}

export default SalonsToCheckPage
