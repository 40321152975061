// eslint-disable-next-line import/prefer-default-export
export enum AUTH_USER {
	AUTH_USER_LOAD_START = 'AUTH_USER_LOAD_START',
	AUTH_USER_LOAD_DONE = 'AUTH_USER_LOAD_DONE',
	AUTH_USER_LOAD_FAIL = 'AUTH_USER_LOAD_FAIL'
}

export enum USER {
	USER_LOAD_START = 'USER_LOAD_START',
	USER_LOAD_DONE = 'USER_LOAD_DONE',
	USER_LOAD_FAIL = 'USER_LOAD_FAIL'
}

export enum USERS {
	USERS_LOAD_START = 'USERS_LOAD_START',
	USERS_LOAD_DONE = 'USERS_LOAD_DONE',
	USERS_LOAD_FAIL = 'USERS_LOAD_FAIL'
}

export enum NOTINO_USERS {
	NOTINO_USERS_LOAD_START = 'NOTINO_USERS_LOAD_START',
	NOTINO_USERS_LOAD_DONE = 'NOTINO_USERS_LOAD_DONE',
	NOTINO_USERS_LOAD_FAIL = 'NOTINO_USERS_LOAD_FAIL'
}

export enum PENDING_INVITES {
	PENDING_INVITES_LOAD_START = 'PENDING_INVITES_LOAD_START',
	PENDING_INVITES_LOAD_DONE = 'PENDING_INVITES_LOAD_DONE',
	PENDING_INVITES_LOAD_FAIL = 'PENDING_INVITES_LOAD_FAIL'
}

export enum USER_DOCUMENTS {
	USER_DOCUMENTS_LOAD_START = 'USER_DOCUMENTS_LOAD_START',
	USER_DOCUMENTS_LOAD_DONE = 'USER_DOCUMENTS_LOAD_DONE',
	USER_DOCUMENTS_LOAD_FAIL = 'USER_DOCUMENTS_LOAD_FAIL'
}

export enum USER_DOCUMENT {
	USER_DOCUMENT_LOAD_START = 'USER_DOCUMENT_LOAD_START',
	USER_DOCUMENT_LOAD_DONE = 'USER_DOCUMENT_LOAD_DONE',
	USER_DOCUMENT_LOAD_FAIL = 'USER_DOCUMENT_LOAD_FAIL'
}

export enum USER_CHANGELOGS {
	USER_CHANGELOGS_LOAD_START = 'USER_CHANGELOGS_LOAD_START',
	USER_CHANGELOGS_LOAD_DONE = 'USER_CHANGELOGS_LOAD_DONE',
	USER_CHANGELOGS_LOAD_FAIL = 'USER_CHANGELOGS_LOAD_FAIL'
}

export enum USER_NOTIFICATION_CENTRE {
	USER_NOTIFICATION_CENTRE_LOAD_START = 'USER_NOTIFICATION_CENTRE_LOAD_START',
	USER_NOTIFICATION_CENTRE_LOAD_DONE = 'USER_NOTIFICATION_CENTRE_LOAD_DONE',
	USER_NOTIFICATION_CENTRE_LOAD_FAIL = 'USER_NOTIFICATION_CENTRE_LOAD_FAIL'
}
