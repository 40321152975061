import { useEffect, useRef } from 'react'
import dayjs from 'dayjs'

// utils
import { GaDialogDurationEvent, GaDialogViewEvent, GaFormDurationEvent, GaFormViewEvent, GaScreenDurationEvent, GaScreenViewEvent, pushEventToDataLayer } from '../utils/dataLayer'
import { DATA_LAYER_EVENTS, GA_DIALOG_NAME, GA_FORM_NAME, GA_SCREEN_CLASS, GA_SCREEN_NAME } from '../utils/dataLayerEnums'
import { DATALAYER_SCREEN_TYPE } from '../utils/enums'

export type OtherDataType = {
	source?: string
}

type ModalShowDialog = {
	screenType: DATALAYER_SCREEN_TYPE.SHOW_DIALOG
	dialogName: GA_DIALOG_NAME
	otherData?: OtherDataType
}

type ModalScreenView = {
	screenType: DATALAYER_SCREEN_TYPE.SCREEN_VIEW
	screenName: GA_SCREEN_NAME
	screenClass: GA_SCREEN_CLASS
	otherData?: OtherDataType
}

type ModalFormView = {
	screenType: DATALAYER_SCREEN_TYPE.FORM
	formName: GA_FORM_NAME
	otherData?: OtherDataType
}

type DataType = ModalShowDialog | ModalScreenView | ModalFormView

/**
 * push screen_view event to dataLayer on modal / sidebar open
 * push screen_duration event to dataLayer on modal / sidebar close (or when user closes the tab / browser)
 */
const usePushModalScreenInfoToDataLayer = (isOpen: boolean, data: DataType) => {
	const startTimeRef = useRef<dayjs.Dayjs | null>(null)
	const { screenType, otherData = {} } = data
	const { source } = otherData

	useEffect(() => {
		const onModalClose = (start: dayjs.Dayjs) => {
			let dataLayerScreenDurationEvent: GaScreenDurationEvent | GaDialogDurationEvent | GaFormDurationEvent

			const defaultValues = {
				event: DATA_LAYER_EVENTS.SCREEN_DURATION,
				screen_time: dayjs().diff(start, 'ms'),
				source: source || undefined
			}

			// eslint-disable-next-line default-case
			switch (screenType) {
				case DATALAYER_SCREEN_TYPE.SCREEN_VIEW:
					dataLayerScreenDurationEvent = {
						...defaultValues,
						screen_name: data.screenName
					}
					break
				case DATALAYER_SCREEN_TYPE.SHOW_DIALOG:
					dataLayerScreenDurationEvent = {
						...defaultValues,
						dialog_name: data.dialogName
					}
					break
				case DATALAYER_SCREEN_TYPE.FORM:
					dataLayerScreenDurationEvent = {
						...defaultValues,
						form_name: data.formName
					}
					break
			}

			pushEventToDataLayer(dataLayerScreenDurationEvent)
		}

		if (isOpen) {
			startTimeRef.current = dayjs()
			const defaultValues = {
				source: source || undefined
			}
			let dataLayerScreenViewEvent: GaScreenViewEvent | GaDialogViewEvent | GaFormViewEvent
			// eslint-disable-next-line default-case
			switch (screenType) {
				case DATALAYER_SCREEN_TYPE.SCREEN_VIEW:
					dataLayerScreenViewEvent = {
						...defaultValues,
						screen_name: data.screenName,
						event: DATA_LAYER_EVENTS.SCREEN_VIEW,
						screen_class: data.screenClass
					}
					break
				case DATALAYER_SCREEN_TYPE.SHOW_DIALOG:
					dataLayerScreenViewEvent = {
						...defaultValues,
						event: DATA_LAYER_EVENTS.SHOW_DIALOG,
						dialog_name: data.dialogName
					}
					break
				case DATALAYER_SCREEN_TYPE.FORM:
					dataLayerScreenViewEvent = {
						...defaultValues,
						event: DATA_LAYER_EVENTS.FORM_VIEW,
						form_name: data.formName
					}
					break
			}

			pushEventToDataLayer(dataLayerScreenViewEvent)
			return
		}

		if (!startTimeRef.current) {
			return
		}

		onModalClose(startTimeRef.current)

		const onBeforeUnload = () => {
			if (startTimeRef.current) {
				onModalClose(startTimeRef.current)
			}
		}
		window.addEventListener('beforeunload', onBeforeUnload)

		// eslint-disable-next-line consistent-return
		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload)
		}
	}, [isOpen, source])

	return null
}

export default usePushModalScreenInfoToDataLayer
