import React, { useState } from 'react'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Row, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce, filter, isArray, isEmpty, isNil, size } from 'lodash'
import { useSelector } from 'react-redux'
import cx from 'classnames'

// components
import Filters from '../../../../components/Filters'
import AsyncJobsModal from '../../../../components/AsyncJobsModal/AsyncJobsModal'

// reducers
import { RootState } from '../../../../reducers'

// assets
import GlobeIcon from '../../../../assets/icons/globe-icon.svg?react'
import CategoryIcon from '../../../../assets/icons/categories-icon.svg?react'
import FilesIcon from '../../../../assets/icons/files-icon.svg?react'

// utils
import { ASYNC_JOB_TYPE, CHANGE_DEBOUNCE_TIME, FIELD_MODE, FORM, OPEN_ASYNC_JOB_MODAL_BUTTON_ID, ROW_GUTTER_X_M, VALIDATION_MAX_LENGTH } from '../../../../utils/enums'
import { formFieldID, optionRenderWithImage } from '../../../../utils/helper'
import {
	getCategoryThirdLevelIDsOptions,
	openingHoursOptions,
	premiumSourceOptions,
	publishedSalonOptions,
	rsAvailableOnlineOptions,
	rsOptions,
	salonChangesOptions,
	salonCreateTypesOptions,
	sourceOptions,
	upcomingReservationsOptions
} from '../salonUtils'
import Permissions from '../../../../utils/Permissions'

// atoms
import InputField from '../../../../atoms/InputField'
import SelectField from '../../../../atoms/SelectField'

// types
import { ISalonsDeletedFilter } from '../../../../types/interfaces'
import { ISalonsDeletedPageURLQueryParams } from '../../../../types/schemaTypes'

type ComponentProps = {
	query: ISalonsDeletedPageURLQueryParams
}

type Props = InjectedFormProps<ISalonsDeletedFilter, ComponentProps> & ComponentProps

export const checkSalonFiltersSize = (formValues: Partial<ISalonsDeletedFilter> | undefined) =>
	size(
		filter(formValues, (value, key) => {
			if (typeof value === 'boolean') {
				return value
			}
			if (isArray(value) && isEmpty(value)) {
				return false
			}
			return (!isNil(value) || !isEmpty(value)) && key !== 'search'
		})
	)

const SalonsFilterDeleted = (props: Props) => {
	const { handleSubmit, query } = props
	const [t] = useTranslation()

	const formValues: Partial<ISalonsDeletedFilter> | undefined = useSelector((state: RootState) => getFormValues(FORM.SALONS_FILTER_DELETED)(state))
	const categories = useSelector((state: RootState) => state.categories.categories)
	const config = useSelector((state: RootState) => state.config.config)
	const [salonsReportModalVisible, setSalonsReportModalVisible] = useState(false)
	const servicesOptions = getCategoryThirdLevelIDsOptions(categories.data)
	const salons = useSelector((state: RootState) => state.salons.salons)
	const totalCount = salons.data?.pagination.totalCount
	const showReportDisabledBtnTooltip = !totalCount
	const disabledReportButton = showReportDisabledBtnTooltip || salons.isLoading

	return (
		<>
			<Form layout='horizontal' onSubmitCapture={handleSubmit} className={'pt-0'}>
				<Filters
					search={
						<Field
							className={'h-10 p-0 m-0'}
							component={InputField}
							size={'large'}
							placeholder={t('loc:Hľadať podľa názvu, adresy alebo ID')}
							name={'search'}
							fieldMode={FIELD_MODE.FILTER}
							search
							maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
						/>
					}
					customContent={
						<Permissions
							render={(hasPermission, { openForbiddenModal }) => (
								<Tooltip title={showReportDisabledBtnTooltip ? t('loc:Report nie je možné vygenerovať z prázdneho zoznamu') : null}>
									{/* Workaround for disabled button inside tooltip: https://github.com/react-component/tooltip/issues/18 */}
									<span className={cx({ 'cursor-not-allowed': disabledReportButton })}>
										<Button
											onClick={() => {
												if (hasPermission) {
													setSalonsReportModalVisible(true)
												} else {
													openForbiddenModal()
												}
											}}
											type='primary'
											htmlType='button'
											className={cx('noti-btn', {
												'pointer-events-none': disabledReportButton
											})}
											icon={<FilesIcon />}
											disabled={disabledReportButton}
											id={formFieldID(FORM.SALONS_FILTER_DELETED, OPEN_ASYNC_JOB_MODAL_BUTTON_ID(ASYNC_JOB_TYPE.EXPORT_SALONS_REPORT))}
										>
											{t('loc:Generovať report')}
										</Button>
									</span>
								</Tooltip>
							)}
						/>
					}
					activeFilters={checkSalonFiltersSize(formValues)}
					form={FORM.SALONS_FILTER_DELETED}
				>
					<Row gutter={ROW_GUTTER_X_M}>
						<Col span={4}>
							<Field
								component={SelectField}
								optionRender={(itemData: any) => optionRenderWithImage(itemData, <GlobeIcon />)}
								name={'countryCode'}
								placeholder={t('loc:Krajina')}
								allowClear
								size={'large'}
								options={config.optionsRolloutCountries}
								loading={config.isLoading}
								disabled={config.isLoading}
							/>
						</Col>
						<Col span={10}>
							<Field
								component={SelectField}
								placeholder={t('loc:Odvetvie')}
								name={'categoryFirstLevelIDs'}
								size={'large'}
								mode={'multiple'}
								showSearch
								loading={categories?.isLoading}
								disabled={categories?.isLoading}
								optionRender={(itemData: any) => optionRenderWithImage(itemData, <CategoryIcon />)}
								allowClear
								filterOption
								options={categories?.enumerationsOptions}
							/>
						</Col>
						<Col span={10}>
							<Field
								component={SelectField}
								placeholder={t('loc:Služby')}
								name={'categoryThirdLevelIDs'}
								mode={'multiple'}
								size={'large'}
								showSearch
								loading={categories?.isLoading}
								disabled={categories?.isLoading}
								allowClear
								filterOption
								options={getCategoryThirdLevelIDsOptions(categories.data)}
								optionRender={(itemData: any) => optionRenderWithImage(itemData, <CategoryIcon />)}
							/>
						</Col>
					</Row>
				</Filters>
			</Form>
			<AsyncJobsModal
				totalCount={totalCount || 0}
				visible={salonsReportModalVisible}
				setVisible={setSalonsReportModalVisible}
				jobType={ASYNC_JOB_TYPE.EXPORT_SALONS_REPORT}
				query={query}
				publishedSalonOptions={publishedSalonOptions()}
				salonChangesOptions={salonChangesOptions()}
				salonCreateTypesOptions={salonCreateTypesOptions()}
				countriesOptions={config.optionsRolloutCountries}
				industriesOptions={categories.enumerationsOptions}
				servicesOptions={servicesOptions}
				sourceOptions={sourceOptions()}
				premiumSourceOptions={premiumSourceOptions()}
				rsOptions={rsOptions()}
				rsAvailableOnlineOptions={rsAvailableOnlineOptions()}
				upcomingReservationsOptions={upcomingReservationsOptions()}
				openingHoursOptions={openingHoursOptions()}
			/>
		</>
	)
}

const form = reduxForm({
	form: FORM.SALONS_FILTER_DELETED,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, CHANGE_DEBOUNCE_TIME),
	destroyOnUnmount: true
})(SalonsFilterDeleted)

export default form
