import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Divider } from 'antd'

// utils
import { getAssignedUserLabel } from '../../../utils/helper'
import { ASYNC_JOB_TYPE, SUBMIT_ASYNC_JOB_BUTTON_ID, WITHOUT_ASSIGNED_USER } from '../../../utils/enums'
import { getReq } from '../../../utils/request'
import { getSalonOrderName } from '../../../pages/SalonsPage/components/salonUtils'

// types
import { GetUrls, ISelectOptionItem, RequestParams } from '../../../types/interfaces'

// schemas
import { ISalonToCheckPageURLQueryParams } from '../../../types/schemaTypes'

// components
import FilterListItem from './FilterListItem'

// redux
import { getSalonsRequestQueryParams } from '../../../reducers/salons/salonsActions'

export type ToCheckSalonsReportRequestQueryParams = RequestParams<GetUrls['/api/b2b/admin/reports/salons/to-check']>['query']

type Props = {
	visible: boolean
	submitting: boolean
	handleSubmit: (query: ToCheckSalonsReportRequestQueryParams) => void
	query: ISalonToCheckPageURLQueryParams
	publishedSalonOptions: ISelectOptionItem[]
	salonChangesOptions: ISelectOptionItem[]
	salonCreateTypesOptions: ISelectOptionItem[]
	countriesOptions: ISelectOptionItem[]
	totalCount: number
}

enum FILTER_KEY {
	ORDER = 'order',
	STATUSES = 'statuses',
	SEARCH = 'search',
	COUNTRY_CODE = 'countryCode',
	ASSIGNED_USER_ID = 'assignedUserID'
}

const ToCheckSalonsReport = (props: Props) => {
	const [t] = useTranslation()
	const { visible, handleSubmit, submitting, query, publishedSalonOptions, salonChangesOptions, salonCreateTypesOptions, countriesOptions, totalCount } = props

	const [assignedUser, setAssignedUser] = useState<string | undefined>(query.assignedUserID || undefined)

	useEffect(() => {
		;(async () => {
			if (visible && query.assignedUserID) {
				if (query.assignedUserID === WITHOUT_ASSIGNED_USER) {
					setAssignedUser(t('loc:Bez Notino používateľa'))
					return
				}

				try {
					const { data } = await getReq('/api/b2b/admin/users/notino-users', {
						params: {
							query: {
								userIDs: [query.assignedUserID]
							}
						},
						reqBody: {}
					})

					const user = data.users[0]
					setAssignedUser(user && user.id === query.assignedUserID ? getAssignedUserLabel(data.users[0]) : query.assignedUserID || undefined)
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error(e)
					setAssignedUser(query.assignedUserID || undefined)
				}
			} else {
				setAssignedUser(undefined)
			}
		})()
	}, [query.assignedUserID, visible, t])

	const handleSubmitReport = () => {
		const params = getSalonsRequestQueryParams(query)

		const requestQuery: ToCheckSalonsReportRequestQueryParams = {
			order: params.order,
			search: params.search,
			assignedUserID: params.assignedUserID || undefined,
			countryCode: params.countryCode,
			createType: params.createType,
			statuses: params.statuses
		}

		handleSubmit(requestQuery)
	}

	const renderFiltersInfo = () => {
		const filters: React.ReactNode[] = []

		if (query.order) {
			filters.push(<FilterListItem key={FILTER_KEY.ORDER} filterName={t('loc:Zoradenie')} filterValue={getSalonOrderName(query.order)} />)
		}

		if (query.search) {
			filters.push(<FilterListItem key={FILTER_KEY.SEARCH} filterName={t('loc:Hľadať podľa názvu, adresy alebo ID')} filterValue={query.search} />)
		}

		if (query.statuses_all || query.statuses_published || query.statuses_changes || query.createType) {
			const statusesTexts: string[] = []
			if (query.statuses_all) {
				statusesTexts.push(t('loc:Všetky'))
			}

			if (query.statuses_published) {
				const statusesPublishedOption = publishedSalonOptions.find((option) => option.value === query.statuses_published)
				statusesTexts.push(statusesPublishedOption?.label || query.statuses_published)
			}

			if (query.statuses_changes) {
				const statusesChangesOption = salonChangesOptions.find((option) => option.value === query.statuses_changes)
				statusesTexts.push(statusesChangesOption?.label || query.statuses_changes)
			}

			if (query.createType) {
				const salonCreateTypeOption = salonCreateTypesOptions.find((option) => option.value === query.createType)
				statusesTexts.push(salonCreateTypeOption?.label || query.createType)
			}

			filters.push(<FilterListItem key={FILTER_KEY.STATUSES} filterName={t('loc:Stavy')} filterValue={statusesTexts.join(', ')} />)
		}

		if (query.countryCode) {
			const countryOption = countriesOptions.find((option) => option.value === query.countryCode)

			filters.push(
				<FilterListItem
					key={FILTER_KEY.COUNTRY_CODE}
					filterName={t('loc:Krajina')}
					filterValue={
						<div className={'inline-flex items-center gap-1'}>
							{countryOption?.extra.image && <img src={countryOption?.extra.image} width={16} height={16} className={'flex-shrink-0'} alt={''} />}
							{countryOption?.label || query.countryCode}
						</div>
					}
				/>
			)
		}

		if (assignedUser) {
			filters.push(<FilterListItem key={FILTER_KEY.ASSIGNED_USER_ID} filterName={t('loc:Priradený Notino používateľ')} filterValue={assignedUser} />)
		}

		return filters
	}

	const disabledButton = submitting

	return (
		<div>
			<h4 className={'mb-0'}>{t('loc:Report pre každý salón zahŕňa')}:</h4>
			<ul className={'pl-8 list-[circle] mb-4 py-4 px-4 rounded-lg bg-notino-grayLighter'}>
				<li>{t('loc:základné informácie')}</li>
				<li>{t('loc:kontaktné údaje')}</li>
				<li>{t('loc:stav salónu')}</li>
				<li>{t('loc:informácie o rezervačnom systéme')}</li>
				<li>{t('loc:informácie o ponúkaných službách')}</li>
				<li>{t('loc:počet kolegov a klientov')}</li>
				<li>{t('loc:dátum vytvorenia')}</li>
				<li>{t('loc:dátum zmeny')}</li>
			</ul>

			<h4 className={'mb-0'}>{t('loc:Vami požadovaný report bude vygenerovaný so zohľadnením filtrácie')}</h4>
			<Divider className={'mb-2 mt-0'} />
			{renderFiltersInfo().length ? (
				<ul className={'pl-5 list-[circle] mb-4 flex flex-col gap-1'}>{renderFiltersInfo()}</ul>
			) : (
				<p className={'text-center my-6'}>{t('loc:Nie sú zvolené žiadne filtre')}</p>
			)}

			<Button
				id={SUBMIT_ASYNC_JOB_BUTTON_ID(ASYNC_JOB_TYPE.EXPORT_SALONS_REPORT)}
				className={`noti-btn sticky bottom-6 shadow-[0px_8px_24px_rgba(255,255,255,0.84),_0px_4px_14px_rgba(255,255,255,0.84),_0px_2px_4px_rgba(255,255,255,0.84)] ${!disabledButton ? `hover:shadow-[0px_8px_24px_rgba(0,0,0,0.24),_0px_4px_14px_rgba(0,0,0,0.14),_0px_2px_4px_rgba(0,0,0,0.16)]` : ''}`}
				block
				size='large'
				type='primary'
				htmlType='button'
				onClick={handleSubmitReport}
				disabled={disabledButton}
				loading={submitting}
			>
				{totalCount !== undefined ? t('loc:Generovať ({{ itemsCount }} záznamov)', { itemsCount: totalCount }) : t('loc:Generovať')}
			</Button>
		</div>
	)
}

export default ToCheckSalonsReport
