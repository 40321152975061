/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Divider, Dropdown, Form, Row, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce, filter, isArray, isEmpty, isNil, size } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'

// components
import Filters from '../../../../components/Filters'
import AsyncJobsModal from '../../../../components/AsyncJobsModal/AsyncJobsModal'

// reducers
import { RootState } from '../../../../reducers'

// assets
import PlusIcon from '../../../../assets/icons/plus-icon.svg?react'
import UploadIcon from '../../../../assets/icons/upload-icon.svg?react'
import GlobeIcon from '../../../../assets/icons/globe-icon.svg?react'
import CategoryIcon from '../../../../assets/icons/categories-icon.svg?react'
import FilesIcon from '../../../../assets/icons/files-icon.svg?react'
import MoreInfoIcon from '../../../../assets/icons/more-info-horizontal-icon.svg?react'

// utils
import {
	ASYNC_JOB_TYPE,
	CHANGE_DEBOUNCE_TIME,
	FIELD_MODE,
	FILTER_ENTITY,
	FORM,
	OPEN_ASYNC_JOB_MODAL_BUTTON_ID,
	PERMISSION,
	ROW_GUTTER_X_M,
	STRINGS,
	VALIDATION_MAX_LENGTH
} from '../../../../utils/enums'
import { formFieldID, getLinkWithEncodedBackUrl, getRangesForDatePicker, optionRenderWithIcon, optionRenderWithImage, optionRenderWithTag } from '../../../../utils/helper'
import Permissions from '../../../../utils/Permissions'
import searchWrapper from '../../../../utils/filters'
import {
	getCategoryThirdLevelIDsOptions,
	notinoUsersInitialOptions,
	openingHoursOptions,
	premiumSourceOptions,
	publishedSalonOptions,
	rsAvailableOnlineOptions,
	rsOptions,
	salonChangesOptions,
	salonCreateTypesOptions,
	sourceOptions,
	upcomingReservationsOptions
} from '../salonUtils'

// atoms
import InputField from '../../../../atoms/InputField'
import SelectField from '../../../../atoms/SelectField'
import DateRangePickerField from '../../../../atoms/DateRangePickerField'
import SwitchField from '../../../../atoms/SwitchField'

// hooks
import useMedia from '../../../../hooks/useMedia'

// types
import { ISalonsActivePageURLQueryParams } from '../../../../types/schemaTypes'
import { ISalonsFilterActive } from '../../../../types/interfaces'

type ComponentProps = {
	query: ISalonsActivePageURLQueryParams
	loading: boolean
}

type Props = InjectedFormProps<ISalonsFilterActive, ComponentProps> & ComponentProps

export const checkSalonFiltersSize = (formValues: Partial<ISalonsFilterActive> | undefined) =>
	size(
		filter(formValues, (value, key) => {
			if (typeof value === 'boolean') {
				return value
			}
			if (isArray(value) && isEmpty(value)) {
				return false
			}
			if (key === 'dateFromTo' && !(value as ISalonsFilterActive['dateFromTo'])?.dateFrom && !(value as ISalonsFilterActive['dateFromTo'])?.dateTo) {
				return false
			}
			return (!isNil(value) || !isEmpty(value)) && key !== 'search' && key !== 'statuses_all'
		})
	)

const SalonsFilterActive = (props: Props) => {
	const { handleSubmit, query, loading } = props
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const firstRender = useRef(true)

	useEffect(() => {
		firstRender.current = false
	}, [])

	const formValues: Partial<ISalonsFilterActive> | undefined = useSelector((state: RootState) => getFormValues(FORM.SALONS_FILTER_ACTIVE)(state))
	const categories = useSelector((state: RootState) => state.categories.categories)
	const config = useSelector((state: RootState) => state.config.config)
	const notinoUsers = useSelector((state: RootState) => state.user.notinoUsers)
	const totalCount = useSelector((state: RootState) => state.salons.salons.data?.pagination.totalCount)

	const [salonImportsModalVisible, setSalonImportsModalVisible] = useState(false)
	const [salonsReportModalVisible, setSalonsReportModalVisible] = useState(false)

	const searchNotinoUsers = async (search: string, page: number) => {
		return searchWrapper(dispatch, { page, search, limit: 100 }, FILTER_ENTITY.NOTINO_USER)
	}

	const servicesOptions = getCategoryThirdLevelIDsOptions(categories.data)

	const isLargerScreen = useMedia(['(max-width: 1280px)'], [true], false)

	const customContent = useMemo(() => {
		const showReportDisabledBtnTooltip = !totalCount
		const disabledReportButton = showReportDisabledBtnTooltip || loading

		const addNewButton = (
			<Permissions
				allowed={[PERMISSION.NOTINO, PERMISSION.PARTNER]}
				render={(hasPermission, { openForbiddenModal }) => (
					<Button
						onClick={() => {
							if (hasPermission) {
								navigate(getLinkWithEncodedBackUrl(t('paths:salons/create')))
							} else {
								openForbiddenModal()
							}
						}}
						type='primary'
						htmlType='button'
						className={'noti-btn w-full'}
						icon={<PlusIcon />}
					>
						{STRINGS(t).addRecord(t('loc:salón'))}
					</Button>
				)}
			/>
		)

		return (
			<div className={'flex items-center gap-2'}>
				{!isLargerScreen ? (
					<>
						<Permissions
							render={(hasPermission, { openForbiddenModal }) => (
								<Tooltip title={showReportDisabledBtnTooltip ? t('loc:Report nie je možné vygenerovať z prázdneho zoznamu') : null}>
									{/* Workaround for disabled button inside tooltip: https://github.com/react-component/tooltip/issues/18 */}
									<span className={cx({ 'cursor-not-allowed': disabledReportButton })}>
										<Button
											onClick={() => {
												if (hasPermission) {
													setSalonsReportModalVisible(true)
												} else {
													openForbiddenModal()
												}
											}}
											type='primary'
											htmlType='button'
											className={cx('noti-btn', {
												'pointer-events-none': disabledReportButton
											})}
											icon={<FilesIcon />}
											disabled={disabledReportButton}
											id={formFieldID(FORM.SALONS_FILTER_ACTIVE, OPEN_ASYNC_JOB_MODAL_BUTTON_ID(ASYNC_JOB_TYPE.EXPORT_SALONS_REPORT))}
										>
											{t('loc:Generovať report')}
										</Button>
									</span>
								</Tooltip>
							)}
						/>
						<Permissions
							allowed={[PERMISSION.IMPORT_SALON]}
							render={(hasPermission, { openForbiddenModal }) => (
								<Button
									onClick={() => {
										if (hasPermission) {
											setSalonImportsModalVisible(true)
										} else {
											openForbiddenModal()
										}
									}}
									type='primary'
									htmlType='button'
									className={'noti-btn w-full'}
									icon={<UploadIcon />}
									id={formFieldID(FORM.SALONS_FILTER_ACTIVE, OPEN_ASYNC_JOB_MODAL_BUTTON_ID(ASYNC_JOB_TYPE.IMPORT_SALONS))}
								>
									{t('loc:Import dát')}
								</Button>
							)}
						/>
						{addNewButton}
					</>
				) : (
					<>
						{addNewButton}
						<Dropdown
							menu={{
								className: 'shadow-md max-w-xs min-w-0 mt-5 noti-dropdown-header',
								items: [
									{
										key: 'download-report',
										className: cx('p-0', { 'hover:bg-transparent': disabledReportButton }),
										disabled: disabledReportButton,
										label: (
											<Permissions
												render={(hasPermission, { openForbiddenModal }) => (
													<Tooltip title={showReportDisabledBtnTooltip ? t('loc:Report nie je možné vygenerovať z prázdneho zoznamu') : null}>
														<div
															role='menuitem'
															tabIndex={-1}
															className={cx('py-2-5 px-2 mb-2 font-medium min-w-0 flex items-center gap-2', {
																'text-notino-gray': disabledReportButton
															})}
															onClick={() => {
																if (disabledReportButton) {
																	return
																}
																if (hasPermission) {
																	setSalonsReportModalVisible(true)
																} else {
																	openForbiddenModal()
																}
															}}
														>
															<FilesIcon />
															{t('loc:Generovať report')}
														</div>
													</Tooltip>
												)}
											/>
										)
									},
									{
										key: 'import-salons',
										className: 'p-0',
										label: (
											<Permissions
												allowed={[PERMISSION.IMPORT_SALON]}
												render={(hasPermission, { openForbiddenModal }) => (
													<div
														role='menuitem'
														tabIndex={-1}
														className={'py-2-5 px-2 mb-2 font-medium min-w-0 flex items-center gap-2'}
														onClick={() => {
															if (hasPermission) {
																setSalonImportsModalVisible(true)
															} else {
																openForbiddenModal()
															}
														}}
													>
														<UploadIcon />
														{t('loc:Import dát')}
													</div>
												)}
											/>
										)
									}
								]
							}}
							placement='bottomRight'
							trigger={['click']}
							overlayStyle={{ minWidth: 226 }}
						>
							<button type={'button'} className={'noti-more-info-btn'} onClick={(e) => e.preventDefault()}>
								<MoreInfoIcon style={{ transform: 'rotate(90deg)' }} color={'#fff'} />
							</button>
						</Dropdown>
					</>
				)}
			</div>
		)
	}, [t, navigate, isLargerScreen, loading, totalCount])

	return (
		<>
			<Form layout='horizontal' onSubmitCapture={handleSubmit} className={'pt-0'}>
				<Filters
					customContent={customContent}
					search={
						<Field
							className={'h-10 p-0 m-0'}
							component={InputField}
							size={'large'}
							placeholder={t('loc:Hľadať podľa názvu, adresy alebo ID')}
							name={'search'}
							fieldMode={FIELD_MODE.FILTER}
							search
							maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
						/>
					}
					activeFilters={checkSalonFiltersSize(formValues)}
					form={FORM.SALONS_FILTER_ACTIVE}
					forceRender
				>
					<>
						<Row>
							<Col span={24}>
								<span className={'font-bold text-xs mb-1'}>{t('loc:Stavy')}</span>
							</Col>
						</Row>

						<Row gutter={ROW_GUTTER_X_M} wrap={false}>
							<Col span={3} className={'statuses-filter-all-col'}>
								<Field component={SwitchField} name={'statuses_all'} size={'large'} label={t('loc:Všetky')} />
							</Col>
							<Row className={'flex-1'} gutter={ROW_GUTTER_X_M}>
								<Col span={8}>
									<Field
										component={SelectField}
										name={'statuses_published'}
										placeholder={t('loc:Publikovaný')}
										className={'select-with-tag-options'}
										allowClear
										size={'large'}
										options={publishedSalonOptions()}
										optionRender={optionRenderWithTag}
									/>
								</Col>
								<Col span={8}>
									<Field
										component={SelectField}
										name={'statuses_changes'}
										placeholder={t('loc:Zmeny')}
										className={'select-with-tag-options'}
										allowClear
										size={'large'}
										options={salonChangesOptions()}
										optionRender={optionRenderWithTag}
									/>
								</Col>
								<Col span={8}>
									<Field
										component={SelectField}
										name={'createType'}
										placeholder={t('loc:Typ salónu')}
										className={'select-with-tag-options'}
										allowClear
										size={'large'}
										options={salonCreateTypesOptions()}
										optionRender={optionRenderWithTag}
									/>
								</Col>
							</Row>
						</Row>

						<Divider className={'mt-0 mb-4'} />

						<Row className={'items-center'} gutter={ROW_GUTTER_X_M}>
							<Col span={4}>
								<Field
									component={SelectField}
									optionRender={(itemData: any) => optionRenderWithImage(itemData, <GlobeIcon />)}
									name={'countryCode'}
									placeholder={t('loc:Krajina')}
									allowClear
									size={'large'}
									options={config.optionsRolloutCountries}
									loading={config.isLoading}
									disabled={config.isLoading}
								/>
							</Col>
							<Col span={10}>
								<Field
									component={SelectField}
									placeholder={t('loc:Odvetvie')}
									name={'categoryFirstLevelIDs'}
									size={'large'}
									mode={'multiple'}
									showSearch
									loading={categories?.isLoading}
									disabled={categories?.isLoading}
									optionRender={(itemData: any) => optionRenderWithImage(itemData, <CategoryIcon />)}
									allowClear
									filterOption
									options={categories?.enumerationsOptions}
								/>
							</Col>
							<Col span={10}>
								<Field
									component={SelectField}
									placeholder={t('loc:Služby')}
									name={'categoryThirdLevelIDs'}
									mode={'multiple'}
									size={'large'}
									showSearch
									loading={categories?.isLoading}
									disabled={categories?.isLoading}
									optionRender={(itemData: any) => optionRenderWithImage(itemData, <CategoryIcon />)}
									allowClear
									filterOption
									options={servicesOptions}
								/>
							</Col>
						</Row>
						<Row gutter={ROW_GUTTER_X_M}>
							<Col span={6}>
								<Field
									component={SelectField}
									name={'sourceType'}
									placeholder={t('loc:Zdroj vytvorenia')}
									className={'select-with-tag-options'}
									allowClear
									size={'large'}
									options={sourceOptions()}
									optionRender={optionRenderWithTag}
								/>
							</Col>
							<Col span={6}>
								<Field
									component={SelectField}
									name={'premiumSourceUserType'}
									placeholder={t('loc:Zdroj PREMIUM')}
									className={'select-with-tag-options'}
									allowClear
									size={'large'}
									options={premiumSourceOptions()}
									optionRender={optionRenderWithTag}
								/>
							</Col>
							<Col span={6}>
								<Field
									component={SelectField}
									name={'enabledReservationsSetting'}
									placeholder={t('loc:Rezervačný systém')}
									allowClear
									size={'large'}
									options={rsOptions()}
									optionRender={(option: any) => optionRenderWithIcon(option, undefined, 24, 24)}
								/>
							</Col>
							<Col span={6}>
								<Field
									component={SelectField}
									name={'hasAvailableReservationSystem'}
									placeholder={t('loc:Dostupné pre online rezervácie')}
									allowClear
									size={'large'}
									options={rsAvailableOnlineOptions()}
									optionRender={(option: any) => optionRenderWithIcon(option, undefined, 24, 24)}
								/>
							</Col>
						</Row>
						<Row className={'flex-1 items-center'} gutter={ROW_GUTTER_X_M}>
							<Col span={6}>
								<Field
									component={SelectField}
									name={'hasUpcomingReservations'}
									placeholder={t('loc:Rezervácie v budúcnosti')}
									allowClear
									size={'large'}
									options={upcomingReservationsOptions}
								/>
							</Col>
							<Col span={6}>
								<Field
									component={SelectField}
									name={'hasSetOpeningHours'}
									placeholder={t('loc:Otváracie hodiny')}
									allowClear
									size={'large'}
									options={openingHoursOptions()}
								/>
							</Col>
							<Col span={6}>
								<Field
									component={SelectField}
									placeholder={t('loc:Priradený Notino používateľ')}
									name={'assignedUserID'}
									size={'large'}
									showSearch
									onSearch={searchNotinoUsers}
									loading={notinoUsers.isLoading}
									allowInfinityScroll
									allowClear
									filterOption={false}
									onDidMountSearch={firstRender.current && !!query?.assignedUserID}
									initialOptions={notinoUsersInitialOptions()}
								/>
							</Col>
							<Col span={6}>
								<Field
									className={'w-full'}
									rangePickerClassName={'w-full'}
									component={DateRangePickerField}
									disableFuture
									placeholder={[t('loc:Úpravy od'), t('loc:Úpravy do')]}
									allowClear
									name={'dateFromTo'}
									presets={getRangesForDatePicker()}
									popupAlign={{ points: ['tr', 'br'] }}
									allowEmpty={[false, false]}
									size={'large'}
								/>
							</Col>
						</Row>
					</>
				</Filters>
			</Form>
			<AsyncJobsModal
				totalCount={totalCount || 0}
				visible={salonsReportModalVisible}
				setVisible={setSalonsReportModalVisible}
				jobType={ASYNC_JOB_TYPE.EXPORT_SALONS_REPORT}
				query={query}
				publishedSalonOptions={publishedSalonOptions()}
				salonChangesOptions={salonChangesOptions()}
				salonCreateTypesOptions={salonCreateTypesOptions()}
				countriesOptions={config.optionsRolloutCountries}
				industriesOptions={categories.enumerationsOptions}
				servicesOptions={servicesOptions}
				sourceOptions={sourceOptions()}
				premiumSourceOptions={premiumSourceOptions()}
				rsOptions={rsOptions()}
				rsAvailableOnlineOptions={rsAvailableOnlineOptions()}
				upcomingReservationsOptions={upcomingReservationsOptions()}
				openingHoursOptions={openingHoursOptions()}
			/>
			<AsyncJobsModal visible={salonImportsModalVisible} setVisible={setSalonImportsModalVisible} jobType={ASYNC_JOB_TYPE.IMPORT_SALONS} />
		</>
	)
}

const form = reduxForm({
	form: FORM.SALONS_FILTER_ACTIVE,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, CHANGE_DEBOUNCE_TIME),
	destroyOnUnmount: true
})(SalonsFilterActive)

export default form
