import * as Sentry from '@sentry/react'
import React, { ErrorInfo, useState } from 'react'
import { ErrorBoundary as ReactErrorBoundary, ErrorBoundaryProps as ReactErrorBoundaryProps, useErrorBoundary } from 'react-error-boundary'
import { Button, Result } from 'antd'
// import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { ERROR_BOUNDARY_TEXTS } from '../../utils/enums'

type FallbackProps = {
	title: string
	description: string
}

// TODO: add translations in next release
const Fallback = ({ title, description }: FallbackProps) => {
	// const [t] = useTranslation()
	// Find the closest ErrorBoundary
	const { resetBoundary } = useErrorBoundary()

	return (
		<div>
			<div role='alert'>
				<div>{title}</div>
				<div>{description}</div>
				<Button onClick={resetBoundary} type='primary'>
					Try again
				</Button>
			</div>
		</div>
	)
}

type ErrorBoundaryProps = Pick<ReactErrorBoundaryProps, 'onReset' | 'children' | 'onError' | 'resetKeys'> & {
	title?: string
	description?: string
}

/**
 * Wrapper for ErrorBoundary component from react-error-boundary package.
 * It provides a simple fallback UI in case of an error, which can either trigger a rerender of the subtree of the error boundary,
 * as well as a report dialog for the user to report the steps that lead to the error.
 */
const ErrorBoundary = ({ onReset, resetKeys, onError, title, description }: ErrorBoundaryProps) => {
	// const [t] = useTranslation()
	const [eventId, setEventId] = useState<string | null>(null)
	const texts = ERROR_BOUNDARY_TEXTS()
	const { language } = i18next

	return (
		<ReactErrorBoundary
			fallbackRender={() => <Fallback title={title ?? 'Something went wrong'} description={description ?? 'Try again'} />}
			onReset={onReset}
			resetKeys={resetKeys}
			onError={(error: Error, info: ErrorInfo) => {
				if (onError) {
					onError(error, info)
				}
				Sentry.withScope((scope) => {
					scope.setExtras(info as { componentStack?: string | null; digest?: string | null }) // NOTE: using ErrorInfo directly causes a type error
					const eventID = Sentry.captureException(error)
					setEventId(eventID)
				})
			}}
		>
			<Result
				status='500'
				title='500'
				subTitle={texts.result.subtitle}
				extra={
					<Button
						onClick={() => {
							Sentry.showReportDialog({
								eventId: eventId || undefined,
								successMessage: texts.reportDialog.successMessage,
								title: texts.reportDialog.title,
								subtitle: texts.reportDialog.subtitle,
								subtitle2: '',
								labelName: texts.reportDialog.labelName,
								labelComments: texts.reportDialog.labelComments,
								labelClose: texts.reportDialog.labelClose,
								labelSubmit: texts.reportDialog.labelSubmit,
								lang: language
							})
						}}
						type='primary'
						className='noti-btn'
					>
						{texts.result.buttonLabel}
					</Button>
				}
			/>
		</ReactErrorBoundary>
	)
}

export default ErrorBoundary
