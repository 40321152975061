import React, { lazy } from 'react'
import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'

// routes middlewares
import PublicRoute from './PublicRoute'
import AuthRoute from './AuthRoute'
import CreatePasswordRoute from './CreatePasswordRoute'
import CancelReservationRoute from './CancelReservationRoute'

// layouts
import MainLayout from '../layouts/MainLayout'
import MainLayoutContent from '../layouts/MainLayoutContent'
import PublicLayout from '../layouts/PublicLayout'

// utils
import { ASSET_TYPE, CATEGORIES_TAB_KEYS, DASHBOARD_TAB_KEYS, MAIN_LAYOUT_CONTENT_CLASSES, NEW_SALON_ID, NOTIFICATIONS_TAB_KEYS, SALONS_TAB_KEYS } from '../utils/enums'
import Navigator from '../utils/navigation'

// Auth
import LoginPage from '../pages/LoginPage/LoginPage'
import LoginAsPartnerPage from '../pages/LoginAsPartnerPage/LoginAsPartnerPage'
import CreatePasswordPage from '../pages/CreatePasswordPage/CreatePasswordPage'
import RegistrationPage from '../pages/RegistrationPage/RegistrationPage'
import ActivationPage from '../pages/ActivationPage/ActivationPage'

// home page
import HomePage from '../pages/HomePage/HomePage'
import NotinoDashboard from '../pages/HomePage/NotinoDashboard'

// Users
import UsersPage from '../pages/UsersPage/UsersPage'
import CreateUserPage from '../pages/UsersPage/CreateUserPage'
import EditUserPage from '../pages/UsersPage/EditUserPage'
import MyProfilePage from '../pages/UsersPage/MyProfilePage'
import AccountSettingsPage from '../pages/UsersPage/AccountSettingsPage'

// Categories
import CategoriesPage from '../pages/CategoriesPage/CategoriesPage'

// Category params
import CategoryParamsPage from '../pages/CategoryParamsPage/CategoryParamsPage'
import CreateCategoryParamsPage from '../pages/CategoryParamsPage/CreateCategoryParamsPage'
import EditCategoryParamsPage from '../pages/CategoryParamsPage/EditCategoryParamsPage'

// Cosmetics
import CosmeticsPage from '../pages/CosmeticsPage/CosmeticsPage'

// Languages
import LanguagesPage from '../pages/LanguagesPage/LanguagesPage'

// Salons
import SalonsPage from '../pages/SalonsPage/SalonsPage'
import SalonPage from '../pages/SalonsPage/SalonPage'

// Support contacts
import SupportContactsPage from '../pages/SupportContactsPage/SupportContactsPage'
import SupportContactPage from '../pages/SupportContactsPage/SupportContactPage'
import ContactPage from '../pages/Contact/ContactPage'

// Specialist contacts
import SpecialistContactsPage from '../pages/SpecialistContactsPage/SpecialistContactsPage'

// Reviews
import ReviewsPage from '../pages/ReviewsPage/ReviewsPage'

// SMS Credits
import SmsCreditAdminPage from '../pages/SmsCreditAdminPage/SmsCreditAdminPage'
import SmsUnitPricesDetailPage from '../pages/SmsCreditAdminPage/SmsUnitPricesDetailPage'
import RechargeSmsCreditAdminPage from '../pages/SmsCreditAdminPage/RechargeSmsCreditAdminPage'

// Documents
import DocumentsPage from '../pages/DocumentsPage/DocumentsPage'
import DocumentsByAssetTypePage from '../pages/DocumentsPage/DocumentsByAssetTypePage'

// Reservations
import CancelReservationPage from '../pages/CancelReservationPage/CancelReservationPage'
import NotinoReservationsPage from '../pages/NotinoReservationsPage/NotinoReservationsPage'

// MS redirect page
import MSRedirectPage from '../pages/MSRedirectPage/MSRedirectPage'

// About application
import AboutApplicationPage from '../pages/AboutApplicationPage/AboutApplicationPage'

// My documents page
import MyDocumentsPage from '../pages/MyDocumentsPage/MyDocumentsPage'
import MyDocumentPage from '../pages/MyDocumentsPage/MyDocumentPage'

// Notifications
import NotificationsPage from '../pages/NotificationsPage/NotificationsPage'
import DynamicNotificationPage from '../pages/NotificationsPage/DynamicNotificationPage'
import CreateDynamicNotificationPage from '../pages/NotificationsPage/CreateDynamicNotificationPage'
import ChangelogPage from '../pages/NotificationsPage/ChangelogPage'

// Banners
import BannersPage from '../pages/BannersPage/BannersPage'

// Legacy document page
import LegacyDocumentPage from '../pages/LegacyDocumentPage/LegacyDocumentPage'

// 404, 403, Error pages
import ForbiddenPage from '../pages/ErrorPages/ForbiddenPage'
import NotFoundPage from '../pages/ErrorPages/NotFoundPage'

// components
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'
import ScrollToTop from '../components/ScrollToTop'

// hooks
import useBroadcastChannelListeners from '../hooks/useBroadcastChannelListeners'
import { GA_SCREEN_NAME } from '../utils/dataLayerEnums'

// dynamic imports
const SalonSubRoutes = lazy(() => import('./SalonSubRoutes'))

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const RoutesInit = () => {
	const navigate = useNavigate()
	Navigator.init(navigate)

	useBroadcastChannelListeners()

	return (
		<ScrollToTop>
			<Outlet />
		</ScrollToTop>
	)
}

const AppRoutes = () => {
	const [t] = useTranslation()

	const router = sentryCreateBrowserRouter(
		createRoutesFromElements(
			<Route element={<RoutesInit />} errorElement={<ErrorBoundary />}>
				{/* // CreatePassword route */}
				<Route errorElement={<ErrorBoundary />} element={<CreatePasswordRoute layout={PublicLayout} />}>
					<Route path={t('paths:reset-password')} element={<CreatePasswordPage />} />
				</Route>
				{/* // Public routes */}
				<Route errorElement={<ErrorBoundary />} element={<PublicRoute layout={PublicLayout} showLegacyDocumentsLinks />}>
					<Route path={t('paths:login')} element={<LoginPage />} />
				</Route>
				<Route errorElement={<ErrorBoundary />} element={<PublicRoute skipRedirectToLoginPage layout={PublicLayout} />}>
					<Route path={t('paths:login-as-partner')} element={<LoginAsPartnerPage />} />
				</Route>
				<Route errorElement={<ErrorBoundary />} element={<PublicRoute layout={PublicLayout} />}>
					<Route path={t('paths:signup')} element={<RegistrationPage />} />
				</Route>
				<Route errorElement={<ErrorBoundary />} element={<PublicRoute skipRedirectToLoginPage layout={PublicLayout} />}>
					<Route path={t('paths:invite')} element={<RegistrationPage />} />
				</Route>
				<Route
					errorElement={<ErrorBoundary />}
					element={
						<PublicRoute skipRedirectToLoginPage showBackButton layout={PublicLayout} gaEvents={{ changeLanguage: { screenName: GA_SCREEN_NAME.HELP_AND_SUPPORT } }} />
					}
				>
					<Route path={t('paths:contact')} element={<ContactPage />} />
				</Route>
				<Route errorElement={<ErrorBoundary />} element={<CancelReservationRoute layout={PublicLayout} />}>
					<Route path={t('paths:cancel-reservation')} element={<CancelReservationPage />} />
				</Route>
				{/* // Legacy documents public routes */}
				<Route errorElement={<ErrorBoundary />} element={<PublicRoute skipRedirectToLoginPage />}>
					<Route path={t('paths:terms-of-use')} element={<LegacyDocumentPage assetType={ASSET_TYPE.B2B_APP_TERMS_CONDITIONS} />} />
					<Route path={t('paths:privacy-policy')} element={<LegacyDocumentPage assetType={ASSET_TYPE.B2B_PRIVACY_POLICY} />} />
				</Route>
				{/* // MS auth redirect route */}
				<Route errorElement={<ErrorBoundary />} path={'/ms-oauth2'} element={<MSRedirectPage />} />
				{/* // Private Routes */}
				<Route element={<AuthRoute layout={MainLayout} />}>
					{/* DASHBOARD */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent contentClassName={MAIN_LAYOUT_CONTENT_CLASSES.DASHBOARD} />}>
						<Route index element={<HomePage />} />
						<Route path={t('paths:reservations-dashboard')} element={<NotinoDashboard tabKey={DASHBOARD_TAB_KEYS.RESERVATIONS_DASHBOARD} />} />
					</Route>
					{/* ACTIVATION PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:activation')}>
						<Route index element={<ActivationPage />} />
					</Route>
					{/* MY PROFILE PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:my-account')}>
						<Route index element={<MyProfilePage />} />
					</Route>
					{/* ACCOUNT SETTINGS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:account-settings')}>
						<Route index element={<AccountSettingsPage />} />
					</Route>
					{/* MY DOCUMENTS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:my-documents')}>
						<Route index element={<MyDocumentsPage />} />
						<Route path={':documentID'} element={<MyDocumentPage />} />
					</Route>
					{/* ABOUT APPLICATION PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:about-application')}>
						<Route index element={<AboutApplicationPage />} />
					</Route>
					{/* USERS */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:users')}>
						<Route index element={<UsersPage />} />
						<Route path={':userID'} element={<EditUserPage />} />
						<Route path={t('paths:createEntity')} element={<CreateUserPage />} />
					</Route>
					{/* CATEGORIES PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:categories')}>
						<Route index element={<CategoriesPage tabKey={CATEGORIES_TAB_KEYS.CATEGORIES} />} />
						<Route path={t('paths:custom-services')}>
							<Route index element={<CategoriesPage tabKey={CATEGORIES_TAB_KEYS.CUSTOM_SERVICES} />} />
						</Route>
					</Route>
					{/* CATEGORY PARAMS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:category-parameters')}>
						<Route index element={<CategoryParamsPage />} />
						<Route path={':parameterID'} element={<EditCategoryParamsPage />} />
						<Route path={t('paths:createEntity')} element={<CreateCategoryParamsPage />} />
					</Route>
					{/* COSMETICS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:cosmetics')}>
						<Route index element={<CosmeticsPage />} />
					</Route>
					{/* LANGUAGES PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:languages-in-salons')}>
						<Route index element={<LanguagesPage />} />
					</Route>
					{/* SUPPORT CONTACTS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:support-contacts')}>
						<Route index element={<SupportContactsPage />} />
						<Route path={':supportContactID'} element={<SupportContactPage />} />
						<Route path={t('paths:createEntity')} element={<SupportContactPage />} />
					</Route>
					{/* SPECIALIST CONTACTS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:specialist-contacts')}>
						<Route index element={<SpecialistContactsPage />} />
					</Route>
					{/* REVIEWS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:reviews')}>
						<Route index element={<ReviewsPage />} />
					</Route>
					{/* DOCUMENTS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:documents')}>
						<Route index element={<DocumentsPage />} />
						<Route path={':assetType'} element={<DocumentsByAssetTypePage />} />
					</Route>
					{/* RESERVATIONS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:reservations')}>
						<Route index element={<NotinoReservationsPage />} />
					</Route>
					{/* SMS CREDITS PAGE */}
					<Route errorElement={<ErrorBoundary />} path={t('paths:sms-credits')}>
						<Route element={<MainLayoutContent contentClassName={MAIN_LAYOUT_CONTENT_CLASSES.DASHBOARD} />}>
							<Route index element={<SmsCreditAdminPage />} />
						</Route>
						<Route element={<MainLayoutContent />}>
							<Route path={':countryCode'} element={<SmsUnitPricesDetailPage />} />
							<Route path={t('paths:recharge')} element={<RechargeSmsCreditAdminPage />} />
						</Route>
					</Route>
					{/* NOTIFICATIONS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:notifications')}>
						<Route index element={<NotificationsPage tabKey={NOTIFICATIONS_TAB_KEYS.DYNAMIC_NOTIFICATIONS} />} />
						<Route path={t('paths:createEntity')} element={<CreateDynamicNotificationPage />} />
						<Route path={':dynamicNotificationID'} element={<DynamicNotificationPage />} />
						<Route path={t('paths:notifications/changelogs')}>
							<Route index element={<NotificationsPage tabKey={NOTIFICATIONS_TAB_KEYS.CHANGELOGS} />} />
							<Route path={t('paths:createEntity')} element={<ChangelogPage />} />
							<Route path={':changelogID'} element={<ChangelogPage />} />
						</Route>
					</Route>
					{/* SALONS PAGE */}
					<Route errorElement={<ErrorBoundary />} path={t('paths:salons')}>
						<Route element={<MainLayoutContent />}>
							<Route index element={<SalonsPage tabKey={SALONS_TAB_KEYS.ACTIVE} />} />
							<Route path={t('paths:deleted')} element={<SalonsPage tabKey={SALONS_TAB_KEYS.DELETED} />} />
							<Route path={t('paths:to-check')} element={<SalonsPage tabKey={SALONS_TAB_KEYS.TO_CHECK} />} />
							<Route path={t('paths:createEntity')} element={<SalonPage salonID={NEW_SALON_ID} />} />
						</Route>
						<Route path={':salonID/*'} element={<SalonSubRoutes />} />
					</Route>
					{/* BANNERS PAGE */}
					<Route errorElement={<ErrorBoundary />} element={<MainLayoutContent />} path={t('paths:banners')}>
						<Route index element={<BannersPage />} />
					</Route>
					{/* 403 PAGE */}
					<Route path={'/403'}>
						<Route index element={<ForbiddenPage />} />
					</Route>
					{/* 404 PAGE */}
					<Route path={'*'} element={<NotFoundPage />} />
				</Route>
				{/* 403 PAGE */}
				<Route path={'/403'}>
					<Route index element={<ForbiddenPage />} />
				</Route>
				{/* 404 PAGE */}
				<Route path={'*'} element={<NotFoundPage />} />
			</Route>
		)
	)

	return <RouterProvider router={router} />
}

export default AppRoutes
