import React, { FC } from 'react'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { isEqual, isNil, some, trimEnd } from 'lodash'
import cx from 'classnames'

import { DEFAULT_LANGUAGE, LANGUAGE, LOCALES } from './enums'

export const isEnumValue = <T extends { [k: string]: string }>(checkValue: any, enumObject: T): checkValue is T[keyof T] =>
	typeof checkValue === 'string' && Object.values(enumObject).includes(checkValue)

export const getLanguageCode = (resolvedLanguage?: string, language?: string) => {
	if (isEnumValue(resolvedLanguage, LANGUAGE)) {
		return resolvedLanguage
	}
	if (isEnumValue(language, LANGUAGE)) {
		return language
	}
	return DEFAULT_LANGUAGE
}

export const getLocale = (resolvedLanguage?: string, language?: string) => {
	return LOCALES[getLanguageCode(language, resolvedLanguage)]
}

export enum DATE_TIME_STYLE {
	FULL = 'full',
	LONG = 'long',
	MEDIUM = 'medium',
	SHORT = 'short'
}

type DateType = dayjs.Dayjs | Date | number | string | undefined | null
type DateTypeIntl = number | Date
type FormatDateTimeByLocaleOptions = {
	dateStyle?: DATE_TIME_STYLE | null
	timeStyle?: DATE_TIME_STYLE | null
	locale?: LANGUAGE | undefined
	fallback?: string
}

/**
 * @param date non localized date
 * @param dateTimeOptions options to specify formatted string
 * @param dateStyle format of the date portion of the formatted string, null means that this portion of the formatted string will be ignored, undefined means use default value
 * @param timeStyle format of the time portion of the formatted string, null means that this portion of the formatted string will be ignored, undefined means use default value
 * @param locale locale to which format non localized date, undefined means use default value
 * @param fallback fallback value returned in case of invalid date or error from Intl API
 * @returns localized date in form of string value or an empty string
 */
export const formatDateTimeByLocale = (date: DateType, dateTimeOptions?: FormatDateTimeByLocaleOptions) => {
	const {
		dateStyle = DATE_TIME_STYLE.MEDIUM,
		timeStyle = DATE_TIME_STYLE.SHORT,
		locale = getLocale(i18next.resolvedLanguage, i18next.language).ISO_Code,
		fallback = ''
	} = dateTimeOptions || {}

	if ((!dateStyle && !timeStyle) || !date) {
		return fallback
	}

	let dateToFormat: DateTypeIntl
	let options: Intl.DateTimeFormatOptions = {}

	if (dateStyle) {
		options = { ...options, dateStyle }
	}

	if (timeStyle) {
		options = { ...options, timeStyle }
	}

	try {
		const asDayjs = dayjs(date)
		if (asDayjs.isValid()) {
			dateToFormat = asDayjs.toDate()
		} else {
			// eslint-disable-next-line no-console
			console.error('Invalid date')
			return fallback
		}
		return new Intl.DateTimeFormat(locale, options).format(dateToFormat)
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e)
		return fallback
	}
}

/**
 * @param dateFrom non localized date
 * @param dateTo non localized date
 * @param dateTimeOptions options to specify formatted string
 * @param dateStyle format of the date portion in both formatted string, null means that this portion in both formatted string will be ignored, undefined means use default value
 * @param timeStyle format of the time portion in both formatted string, null means that this portion in both formatted string will be ignored, undefined means use default value
 * @param locale locale to which format non localized date, undefined means use default value
 * @param fallback fallback value returned in case of invalid dates or error from Intl API
 * @returns localized date range in form of string value or an empty string
 */

export const formatDateTimeRangeByLocale = (dateFrom: DateType, dateTo: DateType, dateTimeOptions?: FormatDateTimeByLocaleOptions) => {
	// format date time range as two separate dates
	const { fallback = '' } = dateTimeOptions || {}

	const dateFromExists = !(isNil(dateFrom) || dateFrom === '')
	const dateToExists = !(isNil(dateTo) || dateTo === '')

	if (!dateFromExists && !dateToExists) {
		return fallback
	}

	if (dateFrom !== dateTo) {
		return `${dateFromExists ? formatDateTimeByLocale(dateFrom, dateTimeOptions) : ''} - ${dateToExists ? formatDateTimeByLocale(dateTo, dateTimeOptions) : ''}`
	}

	return formatDateTimeByLocale(dateFrom || dateTo, dateTimeOptions)
}

/**
 * check if current locale use 12 hour time format
 * @returns boolean
 */
export const is12HourFormat = () => {
	return (
		new Intl.DateTimeFormat(getLocale(i18next.resolvedLanguage, i18next.language).ISO_Code, {
			timeStyle: DATE_TIME_STYLE.MEDIUM
		})
			.formatToParts(new Date())
			.findIndex((part) => part.type === 'dayPeriod' && part.value) !== -1
	)
}

interface IPrice {
	currencySymbol?: string
	currency?: string
	exponent: number
	significand: number
}

/**
 * Transforms number to normalized form
 * @param {number} price
 * @returns {{ exponent: number, significand: number }}
 */
export const encodePrice = (price: number): IPrice => {
	const stringPrice = `${price}`

	let exponent = 0
	const significand = +trimEnd(stringPrice.replace('.', ''), '0')

	if (price % 1 !== 0) {
		exponent = -stringPrice.split('.')[1].length
	} else {
		const reversedSplittedStringPrice = stringPrice.split('').reverse()

		some(reversedSplittedStringPrice, (char) => {
			if (char === '0') {
				exponent += 1

				return false
			}

			return true
		})
	}

	return {
		exponent,
		significand
	}
}

/**
 * Transforms normalized form to number
 * @param {IPrice | null} [price]
 * @returns {number}
 */
export const decodePrice = (price: IPrice | null | undefined): number | null => {
	if (!price) {
		return null
	}

	const { exponent, significand } = price

	// Calculate the decimal places from the exponent
	const decimalPlaces = Math.abs(exponent)

	// Calculate the result
	const result = significand * 10 ** exponent

	// Use toFixed() to format the number with the calculated decimal places
	const formattedResult = result.toFixed(decimalPlaces)

	// Convert the formatted result back to a number
	const numberResult = parseFloat(formattedResult)

	return numberResult
}

type Price = IPrice | number | null | undefined

type FormatPriceByLocaleOptions = {
	locale?: LANGUAGE | undefined
	fallback?: string
} & Omit<Intl.NumberFormatOptions, 'currency'>

const COMMON_INTL_NUMBER_DEFAULT_SETTINGS: Intl.NumberFormatOptions = {
	maximumFractionDigits: 2,
	minimumFractionDigits: 2
}

/**
 * @param price of type number
 * @param currencyCode possible values are ISO 4217 currency codes
 * @param options formatting options, e.g currency style
 * @returns localized price with currency
 */
export const formatPriceByLocale = (price: Price, currencyCode: string | undefined, options?: FormatPriceByLocaleOptions) => {
	const { locale = getLocale(i18next.resolvedLanguage, i18next.language).ISO_Code, fallback = '', ...restOptions } = options || {}

	if (!price || !currencyCode) {
		return fallback
	}

	let formattedPrice: number | undefined | null

	if (typeof price === 'object' && 'exponent' in price && 'significand' in price) {
		formattedPrice = decodePrice(price)
	} else if (typeof price === 'number') {
		formattedPrice = price
	}

	if (isNil(formattedPrice)) {
		return fallback
	}

	try {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currencyDisplay: 'narrowSymbol',
			currency: currencyCode,
			...COMMON_INTL_NUMBER_DEFAULT_SETTINGS,
			...restOptions
		}).format(formattedPrice)
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e)
		return fallback
	}
}

/**
 * @param priceFrom of type number
 * @param priceTo of type number
 * @param currencyCode possible values are ISO 4217 currency codes
 * @param options formatting options, e.g currency style
 * @returns localized price range with currency or fallback value
 */
export const formatPriceRangeByLocale = (priceFrom: Price, priceTo: Price, currencyCode: string | undefined, options?: FormatPriceByLocaleOptions) => {
	const { fallback = '' } = options || {}

	if ((isNil(priceFrom) && isNil(priceTo)) || !currencyCode) {
		return fallback
	}

	if (!isNil(priceFrom) && !isNil(priceTo) && !isEqual(priceFrom, priceTo)) {
		const { locale = getLocale(i18next.resolvedLanguage, i18next.language).ISO_Code, ...restOptions } = options || {}

		let formattedPriceFrom
		let formattedPriceTo

		if (typeof priceFrom === 'object' && 'exponent' in priceFrom && 'significand' in priceFrom) {
			formattedPriceFrom = decodePrice(priceFrom)
		} else {
			formattedPriceFrom = priceFrom
		}

		if (typeof priceTo === 'object' && 'exponent' in priceTo && 'significand' in priceTo) {
			formattedPriceTo = decodePrice(priceTo)
		} else {
			formattedPriceTo = priceTo
		}

		if (isNil(formattedPriceFrom) || isNil(formattedPriceTo)) {
			return fallback
		}

		try {
			return new Intl.NumberFormat(locale, {
				style: 'currency',
				currency: currencyCode,
				currencyDisplay: 'narrowSymbol',
				...COMMON_INTL_NUMBER_DEFAULT_SETTINGS,
				...restOptions
			}).formatRange(formattedPriceFrom, formattedPriceTo)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
			return fallback
		}
	}

	return formatPriceByLocale(priceFrom || priceTo, currencyCode, options)
}

type NumberToFormat = number | null | undefined

type FormatNumberByLocaleOptions = {
	locale?: LANGUAGE | undefined
	fallback?: string
} & Intl.NumberFormatOptions

/**
 * @param number of type number
 * @param options formatting options
 * @returns localized price with currency
 */
export const formaNumberByLocale = (number: NumberToFormat, options?: FormatNumberByLocaleOptions) => {
	const { locale = getLocale(i18next.resolvedLanguage, i18next.language).ISO_Code, fallback = '', ...restOptions } = options || {}

	if (isNil(number)) {
		return fallback
	}

	try {
		return new Intl.NumberFormat(locale, { ...COMMON_INTL_NUMBER_DEFAULT_SETTINGS, ...restOptions }).format(number)
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e)
		return fallback
	}
}

/*
#### AS COMPONENTS ####
*/
type LocalizedDateTimeProps = { date: DateType; ellipsis?: boolean } & FormatDateTimeByLocaleOptions

export const LocalizedDateTime: FC<LocalizedDateTimeProps> = (props) => {
	const { date, ellipsis = false, ...dateTimeOptions } = props
	const formattedDate = formatDateTimeByLocale(date, dateTimeOptions)

	return ellipsis ? (
		<span className={'truncate block'} title={formattedDate}>
			{formattedDate}
		</span>
	) : (
		<>{formattedDate}</>
	)
}

type LocalizedDateTimeRangeProps = { dateFrom: DateType; dateTo: DateType; ellipsis?: boolean } & FormatDateTimeByLocaleOptions

export const LocalizedDateTimeRange: FC<LocalizedDateTimeRangeProps> = (props) => {
	const { dateFrom, dateTo, ellipsis = false, ...dateTimeOptions } = props
	const formatDateTimeRange = formatDateTimeRangeByLocale(dateFrom, dateTo, dateTimeOptions)

	return ellipsis ? (
		<span className={'truncate block'} title={formatDateTimeRange}>
			{formatDateTimeRange}
		</span>
	) : (
		<>{formatDateTimeRange}</>
	)
}

type LocalizedPriceProps = { price: Price; currencyCode: string | undefined; ellipsis?: boolean } & FormatPriceByLocaleOptions

export const LocalizedPrice = (props: LocalizedPriceProps) => {
	const { price, currencyCode, ellipsis = false, ...priceOptions } = props
	const formattedPrice = formatPriceByLocale(price, currencyCode, priceOptions)

	return ellipsis ? (
		<span className={'truncate block'} title={formattedPrice}>
			{formattedPrice}
		</span>
	) : (
		<>{formattedPrice}</>
	)
}

type LocalizedPriceRangeProps = { priceFrom: Price; priceTo: Price; currencyCode: string | undefined; ellipsis?: boolean } & FormatPriceByLocaleOptions

export const LocalizedPriceRange = (props: LocalizedPriceRangeProps) => {
	const { priceFrom, priceTo, currencyCode, ellipsis = false, ...priceOption } = props
	const formattedPriceRange = formatPriceRangeByLocale(priceFrom, priceTo, currencyCode, priceOption)

	return ellipsis ? (
		<span className={'truncate block'} title={formattedPriceRange}>
			{formattedPriceRange}
		</span>
	) : (
		<>{formattedPriceRange}</>
	)
}

type LocalizedPriceWithSuperscriptProps = {
	price: Price
	currencyCode: string | undefined
	options?: FormatPriceByLocaleOptions
	wrapperClassName?: string
	currencyPartClassName?: string
	numberPartClassName?: string
}

export const LocalizedPriceWithSuperscript = (props: LocalizedPriceWithSuperscriptProps) => {
	const { price, currencyCode, options, currencyPartClassName, wrapperClassName, numberPartClassName } = props
	const { locale = getLocale(i18next.resolvedLanguage, i18next.language).ISO_Code, fallback = '', ...restOptions } = options || {}

	if ((!price && price !== 0) || !currencyCode) {
		return fallback
	}

	let formattedPrice: number | undefined | null

	if (typeof price === 'object' && 'exponent' in price && 'significand' in price) {
		formattedPrice = decodePrice(price)
	} else if (typeof price === 'number') {
		formattedPrice = price
	}

	if (isNil(formattedPrice)) {
		return fallback
	}

	try {
		const formatter = new Intl.NumberFormat(locale, {
			style: 'currency',
			currencyDisplay: 'narrowSymbol',
			currency: currencyCode,
			...COMMON_INTL_NUMBER_DEFAULT_SETTINGS,
			...restOptions
		})

		const parts = formatter.formatToParts(formattedPrice)

		// Rozdelenie častí na číslo a symbol meny
		const currencySymbol = parts.find((part) => part.type === 'currency')?.value || ''
		const numberPart = parts
			.filter((part) => part.type !== 'currency')
			.map((part) => part.value)
			.join('')
			.trim()

		// Zisťovanie, či má byť symbol meny pred alebo za číslom
		const isCurrencyFirst = parts[0]?.type === 'currency'

		const currencyPartElement = <span className={cx('text-[70%]', currencyPartClassName)}>{currencySymbol}</span>
		const numberPartElement = <span className={numberPartClassName}>{numberPart}</span>

		return (
			<div className={cx('inline-flex items-center gap-1', wrapperClassName)}>
				{isCurrencyFirst ? (
					<>
						{currencyPartElement}
						{numberPartElement}
					</>
				) : (
					<>
						{numberPartElement}
						{currencyPartElement}
					</>
				)}
			</div>
		)
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e)
		return fallback
	}
}
