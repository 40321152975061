import React, { FC } from 'react'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// reducers
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../reducers'

// utils
import { DATALAYER_SCREEN_TYPE, FORM, RESERVATION_FEEDBACK_STATE, VALIDATION_MAX_LENGTH } from '../../utils/enums'
import { validationCancelReservationConfirmFn } from '../../utils/formValidation'
import { GA_FORM_NAME } from '../../utils/dataLayerEnums'

// atoms
import RadioGroupField from '../../atoms/RadioGroupField'
import TextareaField from '../../atoms/TextareaField'

// types
import { ICancelReservationConfirmForm } from '../../types/interfaces'

// hooks
import usePushModalScreenInfoToDataLayer, { OtherDataType } from '../../hooks/usePushModalScreenInfoToDataLayer'

type ComponentProps = {
	open?: boolean
	cancelConfirmModalScreenInfo?: {
		otherData: OtherDataType
	}
}

type Props = InjectedFormProps<ICancelReservationConfirmForm, ComponentProps> & ComponentProps

const CancelReservationConfirmForm: FC<Props> = (props) => {
	const [t] = useTranslation()
	const { handleSubmit, open, cancelConfirmModalScreenInfo } = props
	const reservationCancellationQuickFeedbackAnswerOptions = useSelector((state: RootState) => state.config.config?.reservationCancellationQuickFeedbackAnswerOptions)
	const formValues: Partial<ICancelReservationConfirmForm> = useSelector((state: RootState) => getFormValues(FORM.CANCEL_RESERVATION_CONFIRM)(state))

	usePushModalScreenInfoToDataLayer(!!open, {
		screenType: DATALAYER_SCREEN_TYPE.FORM,
		formName: GA_FORM_NAME.CANCEL_RESERVATION_FORM,
		otherData: cancelConfirmModalScreenInfo?.otherData
	})

	const options = reservationCancellationQuickFeedbackAnswerOptions?.map((option) => ({
		...option,
		className: 'bg-notino-grayLighter p-4 rounded'
	}))

	return (
		<Form layout='vertical' onSubmitCapture={handleSubmit}>
			<p className={'mb-4'}>{t('loc:Naozaj chcete zrušiť rezerváciu? Klientovi bude zaslaná notifikácia.')}</p>
			<span className={'heading-4'}>{t('loc:Aký je dôvod zrušenia rezervácie?')}</span>
			<Field className={'mt-4'} component={RadioGroupField} name={'quickFeedbackAnswers'} options={options} direction={'vertical'} />
			{formValues?.quickFeedbackAnswers === RESERVATION_FEEDBACK_STATE.OWN_MESSAGE && (
				<Field
					component={TextareaField}
					placeholder={t('loc:Prosím zadajte dôvod zrušenia rezervácie.')}
					maxLength={VALIDATION_MAX_LENGTH.LENGTH_160}
					showLettersCount
					name={'text'}
					size={'large'}
					rows={4}
				/>
			)}
		</Form>
	)
}

const form = reduxForm<ICancelReservationConfirmForm, ComponentProps>({
	form: FORM.CANCEL_RESERVATION_CONFIRM,
	forceUnregisterOnUnmount: true,
	touchOnChange: false,
	touchOnBlur: true,
	destroyOnUnmount: true,
	validate: validationCancelReservationConfirmFn
})(CancelReservationConfirmForm)

export default form
