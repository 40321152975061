/* eslint-disable import/no-cycle */
import i18next from 'i18next'
import { jwtDecode as decode } from 'jwt-decode'
import { get, map, flatten, uniq, includes } from 'lodash'

// types
import { notification } from 'antd'
import { IJwtPayload, ISelectOptionItem, ISearchable, IAuthUserPayload, IUserPayload, RequestResponse, GetUrls, PostUrls } from '../../types/interfaces'
import { AUTH_USER, USER, USERS, PENDING_INVITES, NOTINO_USERS, USER_DOCUMENTS, USER_CHANGELOGS, USER_DOCUMENT, USER_NOTIFICATION_CENTRE } from './userTypes'
import { IResetStore, RESET_STORE } from '../generalTypes'
import { IGetUsersQueryParams } from '../../types/schemaTypes'
import { ThunkResult } from '../index'

// utils
import { setAccessToken, clearAccessToken, clearRefreshToken, setRefreshToken, getAccessToken } from '../../utils/auth'
import { getReq, postReq } from '../../utils/request'
import { getAssignedUserLabel } from '../../utils/helper'
import Navigator from '../../utils/navigation'
import { NOT_ALLOWED_REDIRECT_PATHS } from '../../utils/enums'
import { BROADCAST_CHANNEL_TYPE, BroadcastLoginMessage, BroadcastLogoutMessage, appBroadcastChannel } from '../../utils/broadcast'

// actions
import { setSelectionOptions } from '../selectedSalon/selectedSalonActions'
import { setSelectedCountry } from '../selectedCountry/selectedCountryActions'
import { LOGOUT_NOTIFICATION_KEY } from '../../hooks/useBroadcastChannelListeners'

export type IUserActions =
	| IResetStore
	| IGetAuthUser
	| IGetUser
	| IGetUsers
	| IGetPendingInvites
	| IGetNotinoUsers
	| IGetUserDocuments
	| IGetUserDocument
	| IGetUserChangelogs
	| IUserNotificationCentre

interface IGetAuthUser {
	type: AUTH_USER
	payload: IAuthUserPayload
}

interface IGetUser {
	type: USER
	payload: IUserPayload
}

interface IGetUsers {
	type: USERS
	payload: IUsersPayload
}

interface IGetNotinoUsers {
	type: NOTINO_USERS
	payload: INotinoUsersPayload
}

interface IGetPendingInvites {
	type: PENDING_INVITES
	payload: IPendingInvitesPayload
}

interface IGetUserDocuments {
	type: USER_DOCUMENTS
	payload: IUserDocumentsPayload
}

interface IGetUserDocument {
	type: USER_DOCUMENT
	payload: IUserDocumentPayload
}

interface IGetUserChangelogs {
	type: USER_CHANGELOGS
	payload: IUserChangelogsPayload
}

interface IUserNotificationCentre {
	type: USER_NOTIFICATION_CENTRE
	payload: IUserNotificationCentrePayload
}

export interface IUsersPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/users/']>> {}

export interface INotinoUsersPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/users/notino-users']>> {}

export interface IPendingInvitesPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/users/{userID}/pending-employee-invites']> | null
}

export interface IUserDocumentsPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/users/{userID}/documents/']> | null
}

export interface IUserDocumentPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/users/{userID}/documents/{documentID}']> | null
}

export interface IUserChangelogsPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/users/{userID}/changelogs/']> | null
}

export interface IUserNotificationCentrePayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/users/{userID}']>['user']['notificationCentre'] | null
}

export const processAuthorizationResult =
	(result: RequestResponse<PostUrls['/api/b2b/admin/auth/login']>, redirectPath = i18next.t('paths:index'), postMessage = false): ThunkResult<Promise<void>> =>
	async (dispatch) => {
		let salons: RequestResponse<PostUrls['/api/b2b/admin/auth/login']>['user']['salons'] = []
		const allowRedirectPath = includes(NOT_ALLOWED_REDIRECT_PATHS, redirectPath) ? i18next.t('paths:index') : redirectPath

		try {
			dispatch({ type: AUTH_USER.AUTH_USER_LOAD_START })
			setAccessToken(result.accessToken)
			setRefreshToken(result.refreshToken)
			// parse permissions from role
			const rolePermissions = flatten(map(get(result, 'user.roles'), (role) => get(role, 'permissions')))
			const uniqPermissions = uniq(map([...rolePermissions], 'name'))

			const payload = {
				data: {
					...result.user,
					uniqPermissions
				}
			}

			salons = result.user.salons

			dispatch({
				type: AUTH_USER.AUTH_USER_LOAD_DONE,
				payload
			})

			// set selected country code based on assignedCountryCode or phonePrefixCode
			dispatch(setSelectedCountry(result.user?.assignedCountryCode || result.user?.phonePrefixCountryCode))

			// clear logout notification in case it still exits
			notification.destroy(LOGOUT_NOTIFICATION_KEY)

			if (postMessage) {
				const message: BroadcastLoginMessage = { type: BROADCAST_CHANNEL_TYPE.LOGIN, payload: { user: result.user } }
				appBroadcastChannel.postMessage(message)
			}
			Navigator.navigate(allowRedirectPath)
		} catch (e) {
			dispatch({ type: AUTH_USER.AUTH_USER_LOAD_FAIL })
			Navigator.navigate(i18next.t('paths:login'))
			// eslint-disable-next-line no-console
			console.log(e)
		} finally {
			dispatch(setSelectionOptions(salons))
		}
	}

export const getCurrentUser = (): ThunkResult<Promise<IAuthUserPayload>> => async (dispatch) => {
	let payload = {} as IAuthUserPayload

	let salons: RequestResponse<GetUrls['/api/b2b/admin/users/{userID}']>['user']['salons'] = []

	try {
		dispatch({ type: AUTH_USER.AUTH_USER_LOAD_START })

		const accessToken = getAccessToken()
		const jwtPayload: IJwtPayload = decode(accessToken as string)

		const { data } = await getReq('/api/b2b/admin/users/{userID}', { params: { path: { userID: jwtPayload.uid } }, reqBody: {} })

		// parse permissions from role
		const rolePermissions: any = flatten(map(get(data, 'user.roles'), (role) => get(role, 'permissions')))
		const uniqPermissions = uniq(map([...rolePermissions], 'name'))

		payload = {
			data: {
				...data.user,
				uniqPermissions
			}
		}

		salons = data.user.salons

		dispatch({ type: AUTH_USER.AUTH_USER_LOAD_DONE, payload })
	} catch (err) {
		dispatch({ type: AUTH_USER.AUTH_USER_LOAD_FAIL })
		// eslint-disable-next-line no-console
		console.error(err)
	} finally {
		dispatch(setSelectionOptions(salons))
	}

	return payload
}

export const logOutUser =
	(skipRedirect?: boolean): ThunkResult<Promise<void>> =>
	async (dispatch, getStore) => {
		try {
			const store = getStore()
			await postReq('/api/b2b/admin/auth/logout', {
				params: {},
				reqBody: {},
				customConfig: {
					displayNotification: false
				}
			})
			const message: BroadcastLogoutMessage = { type: BROADCAST_CHANNEL_TYPE.LOGOUT, payload: { user: store.user.authUser.data } }
			appBroadcastChannel.postMessage(message)
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		} finally {
			clearAccessToken()
			clearRefreshToken()

			await dispatch({
				type: RESET_STORE
			})

			if (!skipRedirect) {
				Navigator.navigate(i18next.t('paths:login'))
			}
		}
	}

export const getUser =
	(userID: string): ThunkResult<Promise<IUserPayload>> =>
	async (dispatch) => {
		let payload = {} as IUserPayload
		try {
			dispatch({ type: USER.USER_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/users/{userID}', { params: { path: { userID } }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: USER.USER_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: USER.USER_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getUsers =
	(queryParams: IGetUsersQueryParams): ThunkResult<Promise<IUsersPayload>> =>
	async (dispatch) => {
		let payload = {} as IUsersPayload
		try {
			dispatch({ type: USERS.USERS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/users/', {
				params: {
					query: {
						search: queryParams.search || undefined,
						page: queryParams.page,
						limit: queryParams.limit,
						order: queryParams.order,
						roleID: queryParams.roleID || undefined
					}
				},
				reqBody: {}
			})

			const usersOptions: ISelectOptionItem[] = map(data?.users, (user) => ({
				key: user?.id,
				label: user?.firstName || user?.lastName ? `${user?.firstName} ${user?.lastName}` : user?.email,
				value: user?.id
			}))

			payload = {
				data,
				options: usersOptions
			}

			dispatch({ type: USERS.USERS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: USERS.USERS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getNotinoUsers =
	(queryParams: IGetUsersQueryParams): ThunkResult<Promise<INotinoUsersPayload>> =>
	async (dispatch) => {
		let payload = {} as INotinoUsersPayload
		try {
			dispatch({ type: NOTINO_USERS.NOTINO_USERS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/users/notino-users', {
				params: {
					query: {
						search: queryParams.search || undefined,
						page: queryParams.page,
						limit: queryParams.limit
					}
				},
				reqBody: {}
			})

			const usersOptions =
				data?.users.map((user) => ({
					key: user.id,
					label: getAssignedUserLabel(user),
					value: user.id
				})) || []

			payload = {
				data,
				options: usersOptions
			}

			dispatch({ type: NOTINO_USERS.NOTINO_USERS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: NOTINO_USERS.NOTINO_USERS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getPendingInvites =
	(userID: string): ThunkResult<Promise<IPendingInvitesPayload>> =>
	async (dispatch) => {
		let payload = {} as IPendingInvitesPayload
		try {
			dispatch({ type: PENDING_INVITES.PENDING_INVITES_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/users/{userID}/pending-employee-invites', { params: { path: { userID } }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: PENDING_INVITES.PENDING_INVITES_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: PENDING_INVITES.PENDING_INVITES_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getUserDocuments =
	(userID: string): ThunkResult<Promise<IUserDocumentsPayload>> =>
	async (dispatch) => {
		let payload = {} as IUserDocumentsPayload
		try {
			dispatch({ type: USER_DOCUMENTS.USER_DOCUMENTS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/users/{userID}/documents/', { params: { path: { userID } }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: USER_DOCUMENTS.USER_DOCUMENTS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: USER_DOCUMENTS.USER_DOCUMENTS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getUserDocument =
	(userID: string, documentID: string): ThunkResult<Promise<IUserDocumentPayload>> =>
	async (dispatch) => {
		let payload = {} as IUserDocumentPayload
		try {
			dispatch({ type: USER_DOCUMENT.USER_DOCUMENT_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/users/{userID}/documents/{documentID}', { params: { path: { userID, documentID } }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: USER_DOCUMENT.USER_DOCUMENT_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: USER_DOCUMENT.USER_DOCUMENT_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getUserChangelogs =
	(userID: string, showNotification: boolean = false): ThunkResult<Promise<IUserChangelogsPayload>> =>
	async (dispatch) => {
		let payload = {} as IUserChangelogsPayload
		try {
			dispatch({ type: USER_CHANGELOGS.USER_CHANGELOGS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/users/{userID}/changelogs/', {
				params: { path: { userID } },
				reqBody: {},
				customConfig: { displayNotification: showNotification }
			})

			payload = {
				data
			}

			dispatch({ type: USER_CHANGELOGS.USER_CHANGELOGS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: USER_CHANGELOGS.USER_CHANGELOGS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getUserNotificationsCentre = (): ThunkResult<Promise<IUserNotificationCentrePayload>> => async (dispatch, getState) => {
	let payload = {} as IUserNotificationCentrePayload

	try {
		const authUser = getState().user.authUser.data
		dispatch({ type: USER_NOTIFICATION_CENTRE.USER_NOTIFICATION_CENTRE_LOAD_START, payload })

		if (authUser) {
			const { data } = await getReq('/api/b2b/admin/users/{userID}', { params: { path: { userID: authUser.id } }, reqBody: {} })

			payload = {
				data: data.user.notificationCentre
			}
			dispatch({ type: USER_NOTIFICATION_CENTRE.USER_NOTIFICATION_CENTRE_LOAD_DONE, payload })
		}
	} catch (err) {
		dispatch({ type: USER_NOTIFICATION_CENTRE.USER_NOTIFICATION_CENTRE_LOAD_FAIL })
		// eslint-disable-next-line no-console
		console.error(err)
	}

	return payload
}
