import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Spin } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

// types
import { IBreadcrumbs } from '../../types/interfaces'
import { RootState } from '../../reducers'
import { DATA_LAYER_EVENTS, GA_SCREEN_CLASS, GA_SCREEN_NAME } from '../../utils/dataLayerEnums'

// components
import Breadcrumbs from '../../components/Breadcrumbs'

// assets
import AttachIcon from '../../assets/icons/attach-icon.svg?react'

// utils
import { patchReq } from '../../utils/request'
import { LocalizedDateTime } from '../../utils/intl'
import { CYPRESS_CLASS_NAMES } from '../../utils/enums'
import { pushEventToDataLayer } from '../../utils/dataLayer'

// redux
import { getCurrentUser, getUserDocument } from '../../reducers/users/userActions'

// hooks
import usePushScreenInfoToDataLayer from '../../hooks/usePushScreenInfoToDataLayer'

const MyDocumentPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { documentID } = useParams<{ documentID?: string }>()

	const document = useSelector((state: RootState) => state.user.userDocument)
	const authUser = useSelector((state: RootState) => state.user.authUser)
	const userID = authUser?.data?.id

	// Google Analytics initial events
	usePushScreenInfoToDataLayer(GA_SCREEN_NAME.DOCUMENT_DETAIL, GA_SCREEN_CLASS.DOCUMENT_DETAIL)

	useEffect(() => {
		;(async () => {
			if (userID && documentID) {
				dispatch(getUserDocument(userID, documentID))
			}
		})()
	}, [dispatch, navigate, userID, documentID])

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Dokumenty'),
				link: t('paths:my-documents')
			},
			{
				name: document?.data?.document.name || ''
			}
		]
	}

	const markAsRead = async (fileID: string) => {
		if (document?.data?.document?.readAt) {
			return
		}

		if (documentID && userID) {
			try {
				await patchReq('/api/b2b/admin/users/{userID}/documents/{documentID}/mark-as-read', { params: { path: { userID, documentID } }, reqBody: { fileIDs: [fileID] } })
				// refresh documents to prevent another markAsRead action for already read document
				await dispatch(getUserDocument(userID, documentID))
				// refresh current user to update badge in My profile menu
				dispatch(getCurrentUser())
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		}
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Row>
			<Spin spinning={document.isLoading}>
				<div className='content-body medium'>
					<h4 className={'text-notino-black text-lg mb-6'}>{document?.data?.document.name}</h4>
					<p className={'text-notino-grayDarker whitespace-pre-wrap'}>{document?.data?.document.message}</p>
					<p className={'text-notino-grayDarker mb-6'}>
						{t('loc:Platnosť od')}: <LocalizedDateTime date={document?.data?.document.createdAt} />
					</p>
					<div className={'border-t border-t-notino-grayLight pt-4 flex flex-col items-start'} style={{ borderTopStyle: 'solid' }}>
						{document?.data?.document?.files.map((file) => {
							return (
								<div className={'flex items-center gap-4'}>
									<a
										key={file.id}
										href={file.original}
										target='_blank'
										rel='noreferrer'
										onClick={() => {
											pushEventToDataLayer({
												event: DATA_LAYER_EVENTS.CLICK_BUTTON,
												button_name: 'download_document',
												screen_name: GA_SCREEN_NAME.DOCUMENT_DETAIL
											})

											markAsRead(file.id)
										}}
										className={`${CYPRESS_CLASS_NAMES.MY_DOCUMENT_LINK} text-notino-pink hover:text-notino-black inline-flex items-center gap-2`}
									>
										<AttachIcon className={'flex-shrink-0 text-notino-black'} />
										{file.fileName}
									</a>
								</div>
							)
						})}
					</div>
				</div>
			</Spin>
		</>
	)
}

export default MyDocumentPage
