import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Row, Spin, TableProps, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { useParams } from 'react-router-dom'
import { destroy, initialize } from 'redux-form'
import { ColumnsType } from 'antd/es/table'

// components
import CustomTable from '../../components/CustomTable'
import Breadcrumbs from '../../components/Breadcrumbs'
import FlagIcon from '../../components/FlagIcon'
import DocumentsForm from './components/DocumentsForm'
import CopyToClipboardButton from '../../components/CopyToClipboardButton/CopyToClipboardButton'

// utils
import { ADMIN_PERMISSIONS, ASSET_TYPE, PAGINATION, FORM, LANGUAGE, ADD_BUTTON_ID } from '../../utils/enums'
import { formFieldID, normalizeDirectionKeys } from '../../utils/helper'
import { withPermissions } from '../../utils/Permissions'
import { postReq } from '../../utils/request'
import { LocalizedDateTime, getLanguageCode, getLocale } from '../../utils/intl'

// reducers
import { RootState } from '../../reducers'

// types
import { IBreadcrumbs, IDocumentForm, PostUrls, RequestPayload } from '../../types/interfaces'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'
import useBackUrl from '../../hooks/useBackUrl'

// redux
import { IDocumentsByAssetTypePayload, getAssetTypes, getDocumentsByAssetType } from '../../reducers/documents/documentActions'
import { getCurrentUser } from '../../reducers/users/userActions'

// assets
import PlusIcon from '../../assets/icons/plus-icon.svg?react'

// schemas
import { documentsAssetTypesPageURLQueryParamsSchema } from '../../schemas/queryParams'

const { Paragraph } = Typography

type TableDataItem = NonNullable<IDocumentsByAssetTypePayload['data']>['documents'][0]
type PostRequestDocumentBody = RequestPayload<PostUrls['/api/b2b/admin/documents/']>

const DocumentsByAssetTypePage = () => {
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation()
	const { assetType } = useParams<Required<{ assetType: ASSET_TYPE }>>()

	const documentsByAssetType = useSelector((state: RootState) => state.documents.documentsByAssetType)
	const assetTypes = useSelector((state: RootState) => state.documents.assetTypes)
	const [visible, setVisible] = useState(false)

	const [backUrl] = useBackUrl(t('paths:documents'))

	const assetTypeItem = assetTypes?.data?.assetTypes.find((item) => item.key === assetType)
	const assetTypeName = assetTypeItem?.name
	const isLoading = documentsByAssetType?.isLoading

	const [query, setQuery] = useQueryParams(documentsAssetTypesPageURLQueryParamsSchema, {
		page: 1,
		limit: PAGINATION.limit,
		languageCode: getLanguageCode(i18n.language)
	})

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Prehľad dokumentov'),
				link: backUrl
			},
			{
				name: assetTypeName || ''
			}
		]
	}

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (_pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}`
			const newQuery = {
				...query,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === documentsByAssetType?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}
	useEffect(() => {
		dispatch(getAssetTypes())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (assetType) {
			dispatch(getDocumentsByAssetType({ ...query, assetType }))
		}
	}, [assetType, dispatch, query])

	const fileUploadSubmit = async (values: IDocumentForm) => {
		try {
			const fileIDs = values.files.map((item: { id: string }) => item.id)

			let reqBody: PostRequestDocumentBody = {
				languageCode: values.languageCode as LANGUAGE,
				fileIDs,
				message: (values.message as any) || null,
				assetType: values.assetType.value as ASSET_TYPE
			}

			if (values.createNotification && values.notificationTitle && values.notificationContent) {
				reqBody = {
					...reqBody,
					dynamicNotification: {
						title: values.notificationTitle,
						content: values.notificationContent
					}
				}
			}

			await postReq('/api/b2b/admin/documents/', { params: {}, reqBody })
			setVisible(false)
			dispatch(destroy(FORM.DOCUMENTS_FORM))
			// get current user to reload notifications in my profile menu
			dispatch(getCurrentUser())
			if (assetType) {
				dispatch(getDocumentsByAssetType({ ...query, assetType }))
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	const columns: ColumnsType<TableDataItem> = [
		{
			title: t('loc:Jazyk'),
			dataIndex: 'languageCode',
			key: 'languageCode',
			ellipsis: true,
			render: (value: TableDataItem['languageCode']) => {
				const language = value.toLowerCase()
				return (
					<div className={'flex items-center gap-2'}>
						<FlagIcon language={language as LANGUAGE} />
						{getLocale(value).countryCode.toUpperCase()}
					</div>
				)
			}
		},
		{
			title: t('loc:Sprievodná správa'),
			dataIndex: 'message',
			key: 'message',
			render: (value: TableDataItem['message']) => (
				<Paragraph
					className={'m-0 whitespace-pre-wrap'}
					ellipsis={{
						rows: 1,
						expandable: true,
						symbol: t('loc:Zobraziť viac')
					}}
					title={value}
				>
					{value}
				</Paragraph>
			)
		},
		{
			title: t('loc:Dátum poslednej aktualizácie'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			ellipsis: true,
			render: (value: TableDataItem['createdAt']) => <LocalizedDateTime date={value} fallback={'-'} ellipsis />
		},
		{
			title: t('loc:Kopírovať odkaz'),
			dataIndex: 'copyLink',
			key: 'copyLink',
			ellipsis: true,
			render: (_value, record) => {
				return record.files.map((item) => {
					return <CopyToClipboardButton key={item.id} title={t('loc:Kopírovať odkaz')} link={'shortLinkUrl' in item ? item.shortLinkUrl : item.original} />
				})
			}
		},
		{
			title: t('loc:Dokument'),
			dataIndex: 'files',
			key: 'files',
			ellipsis: true,
			render: (value: TableDataItem['files']) => {
				return value.map((item) => {
					return (
						<Button
							key={item.id}
							className={'noti-btn text-notino-pink text-left p-0 hover:text-black'}
							href={item.original}
							target='_blank'
							rel='noopener noreferrer'
							type={'link'}
							htmlType={'button'}
							download
						>
							{item.fileName}
						</Button>
					)
				})
			}
		}
	]

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Row>
			<h2 className={'text-2xl mb-4 mt-8'}>{t('loc:História aktualizácií dokumentu')}</h2>
			<div className='content-body transparent-background'>
				<Spin spinning={isLoading}>
					<div className={'flex items-center justify-between bg-notino-grayLighter p-4 min-h-16 shadow-md'}>
						<h3 className={'mb-0 text-base text-bold'}>{assetTypeName}</h3>
						<Button
							id={formFieldID(FORM.DOCUMENTS_FORM, ADD_BUTTON_ID)}
							onClick={(e) => {
								e.stopPropagation()
								setVisible(true)
								if (assetTypeItem) {
									dispatch(
										initialize(FORM.DOCUMENTS_FORM, {
											assetType: {
												key: assetTypeItem.key,
												value: assetTypeItem.key,
												label: assetTypeItem.name,
												extra: {
													mimeTypes: assetTypeItem.mimeTypes,
													fileType: assetTypeItem.fileType,
													maxFilesCount: assetTypeItem.maxFilesCount
												}
											},
											languageCode: query.languageCode,
											id: assetTypeItem.key
										})
									)
								}
							}}
							type='primary'
							htmlType='button'
							className={'noti-btn'}
							icon={<PlusIcon />}
						>
							{t('loc:Nahrať dokument')}
						</Button>
					</div>
					<CustomTable
						className='table-fixed'
						wrapperClassName={'noti-collapse-panel-table bordered'}
						onChange={onChangeTable}
						columns={columns}
						dataSource={documentsByAssetType.data?.documents || []}
						rowClassName={'h-10 py-3'}
						rowKey='id'
						scroll={{ x: 1200 }}
						useCustomPagination
						pagination={{
							pageSize: documentsByAssetType?.data?.pagination?.limit,
							total: documentsByAssetType?.data?.pagination?.totalCount,
							current: documentsByAssetType?.data?.pagination?.page,
							onChange: onChangePagination,
							disabled: documentsByAssetType?.isLoading
						}}
					/>
				</Spin>
				<DocumentsForm visible={visible} setVisible={setVisible} onSubmit={fileUploadSubmit} />
			</div>
		</>
	)
}
export default compose(withPermissions([...ADMIN_PERMISSIONS]))(DocumentsByAssetTypePage)
